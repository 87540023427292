import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { GlobalTypeScriptService } from '../servicess/global-type-script.service';
import { AuthservService } from '../servicess/authserv.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgForm } from '@angular/forms';
import { text } from '@angular/core/src/render3';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css']
})
export class CustomerDetailsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  constructor(private ActivatedRoute: ActivatedRoute, private authserve: AuthservService, private http: HttpClient, private connectorService: ConnectorsService, private Router: Router, private activatedRoute: ActivatedRoute) { }
  _customerID: any;
  _connectorslist: any = [{ fullName: '', email: '', phone: '', company: '', registeredOn: '' }];
  _Connectors: any;
  _subscriptionExists: any = false;
  _showDefaultSubscriptions: any = false;
  _message: any;
  _arr_ErpRecords: Array<any> = [];
  item_Import: any = false;
  item_Export: any = false;
  inventory_Import: any = false;
  inventory_Export: any = false;
  price_Import: any = false;
  price_Export: any = false;
  image_Import: any = false;
  image_Export: any = false;
  orders_Import: any = false;
  orders_Export: any = false;
  shipments_Import: any = false;
  shipments_Export: any = false;
  refunds_Import: any = false;
  refunds_Export: any = false;
  returns_Import: any = false;
  returns_Export: any = false;
  billing_Import: any = false;
  billing_Export: any = false;
  cancellation_Import: any = false;
  cancellation_Export: any = false;
  Subsidiary_Export: any = false;
  Customer_Export: any = false;

  chkItemImport: any = false;
  chkItemeExport: any = false;
  chkPriceImport: any = false;
  chkPriceExport: any = false;
  chkInvenImport: any = false;
  chkInveneExport: any = false;
  chkImageImport: any = false;
  chkImageExport: any = false;
  chkOrdersImport: any = false;
  chkOrdersExport: any = false;
  chkBillingImport: any = false;
  chkBillingExport: any = false;
  chkShipmentsImport: any = false;
  chkShipmentsExport: any = false;
  chkCancellationImport: any = false;
  chkCancellationExport: any = false;
  chkRefundsImport: any = false;
  chkRefundsExport: any = false;
  chkReturnsImport: any = false;
  chkReturnsExport: any = false;

  _apiItemImport: any = "";
  _apiItemeExport: any = "";
  _apiPriceImport: any = "";
  _apiPriceExport: any = "";
  _apiInvenImport: any = "";
  _apiInveneExport: any = "";
  _apiImageImport: any = "";
  _apiImageExport: any = "";
  _apiOrdersImport: any = "";
  _apiOrdersExport: any = "";
  _apiBillingImport: any = "";
  _apiBillingExport: any = "";
  _apiShipmentsImport: any = "";
  _apiShipmentsExport: any = "";
  _apiCancellationImport: any = "";
  _apiCancellationExport: any = "";
  _apiRefundsImport: any = "";
  _apiRefundsExport: any = "";
  _apiReturnsImport: any = "";
  _apiReturnsExport: any = "";


  _validateSuccess: any = false;
  _showDiv: any = false;
  _showCustomAPI: any = false;
  Expdate: any;
  _isCustomizableSubscription: any = false;
  _isCustomizationList: any = false;
  _pkIntegratorID: any = 0;
  _tempConnectorID: any = "";
  _Pending: any = true;
  @ViewChild('f') indexForm: NgForm;
  @ViewChild('closeAddExpenseModal') closeAddExpenseModal: ElementRef;

  _upConnectorName: any;
  _upSubscription: any;
  _upExpiryDate: any;
  _upLicensekey: any;
  _upAppCode: any;
  _defaultValues: any = { ERP: "", Connector: "", Subscription: "", License: "", AppCode: "" };
  _cutomApiValues: any = {
    _aaitem_Import: "", _akititem_Import: "", _agroupitem_Import: "", _amatrixitem_Import: "",
    _aitem_Export: "", _akititem_Export: "", _agroupitem_Export: "", _amatrixitem_Export: "",
    _ainventory_Import: "", _ainventory_Export: "", _aprice_Import: "", _aprice_Export: "",
    _aimage_Import: "", _aimage_Export: "", _aorders_Import: "", _aorders_Export: "", _ashipments_Import: "", _ashipments_Export: "",
    _arefunds_Import: "", _arefunds_Export: "", _areturns_Import: "", _areturns_Export: "",
    _abilling_Import: "", _abilling_Export: "", _aorderinvocie: "", _aordercashsale: "",
    _acancellation_Import: "", _acancellation_Export: "", _aSubsidiary_Export: "", _aCustomer_Export: ""
  }
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this._customerID = params['Customer'];
      this.GetCustomerConnectors(this._customerID);
      this.GetERPRecords();
    });
  }
  GetERPRecords() {
    this.connectorService.GetERPRecords().subscribe((data: any) => {
      if (data.status != "Error") {
        this._arr_ErpRecords = data.results;
      }
    })
  }

  GetCustomerConnectors(customerid) {
    this.connectorService.GetCustomerConnectors(customerid).subscribe((data: any) => {
      if (data.results[0].nS_Account == "" || data.results[0].nS_Account == null) {
        this._Pending = false;
      }
      if (data.status == "Success") {
        this._showDiv = true;
        this._connectorslist = data.results;
      }
      else {

      }
    }, error => {

    })
  }

  GetConnectorNames() {
    var ERP = this.indexForm.value.ddlERP;
    this.connectorService.GetConnectorNamesList(ERP).subscribe((data: any) => {
      if (data.status == "Success") {
        this._Connectors = data.results;
      }
    }, error => {

    })
  }

  _funLicenseDetails() {
    this._validateSuccess = false;
  }

  GetSubscriptionPlan() {
    this.falseAllConditions();
    if ((this.indexForm.value.ddlConnector != '' || this._tempConnectorID != '') && this.indexForm.value.ddlSubscription != '' && this.indexForm.value.ddlERP != '') {
      var Connector = this.indexForm.value.ddlConnector;
      if (Connector == "" || Connector == null || Connector == undefined)
        Connector = this._tempConnectorID;
      var Subscription = this.indexForm.value.ddlSubscription;
      var ERP = this.indexForm.value.ddlERP;
      this.connectorService.GetSubscriptionPlan(Connector, Subscription, ERP).subscribe((data: any) => {
        if (data.status == "Success") {
          if (data.results[0].needActivationKey == false) {
            this.falseAllConditions();
            this._showDefaultSubscriptions = false;
            this._subscriptionExists = false;
            this._validateSuccess = true;
          } else {
            if (data.results.length == 0) {
              this._showDefaultSubscriptions = false;
              this._subscriptionExists = false;
            }
            else {
              this._showDefaultSubscriptions = true;
              this._subscriptionExists = true;
            }
            this.item_Import = data.results[0].item_Import;
            this.item_Export = data.results[0].item_Export;
            this.inventory_Import = data.results[0].inventory_Import;
            this.inventory_Export = data.results[0].inventory_Export;
            this.price_Import = data.results[0].price_Import;
            this.price_Export = data.results[0].price_Export;
            this.image_Import = data.results[0].image_Import;
            this.image_Export = data.results[0].image_Export;
            this.orders_Import = data.results[0].orders_Import;
            this.orders_Export = data.results[0].orders_Export;
            this.shipments_Import = data.results[0].shipments_Import;
            this.shipments_Export = data.results[0].shipments_Export;
            this.refunds_Import = data.results[0].refunds_Import;
            this.refunds_Export = data.results[0].refunds_Export;
            this.returns_Import = data.results[0].returns_Import;
            this.returns_Export = data.results[0].returns_Export;
            this.billing_Import = data.results[0].billing_Import;
            this.billing_Export = data.results[0].billing_Export;
            this.cancellation_Import = data.results[0].cancellation_Import;
            this.cancellation_Export = data.results[0].cancellation_Export;
            this.Subsidiary_Export = data.results[0].subsidiary_Export;
            this.Customer_Export = data.results[0].customers_Export;
          }
        }
      }, error => {

      })

    }
  }

  falseAllConditions() {
    this.chkItemImport = false;
    this.chkItemeExport = false;
    this.chkPriceImport = false;
    this.chkPriceExport = false;
    this.chkInvenImport = false;
    this.chkInveneExport = false;
    this.chkImageImport = false;
    this.chkImageExport = false;
    this.chkOrdersImport = false;
    this.chkOrdersExport = false;
    this.chkBillingImport = false;
    this.chkBillingExport = false;
    this.chkShipmentsImport = false;
    this.chkShipmentsExport = false;
    this.chkCancellationImport = false;
    this.chkCancellationExport = false;
    this.chkRefundsImport = false;
    this.chkRefundsExport = false;
    this.chkReturnsImport = false;
    this.chkReturnsExport = false;
    this._subscriptionExists = false; this._showDefaultSubscriptions = false; this._isCustomizableSubscription = false;
  }

  onSubmit() {
    this._message = "";
    this.blockUI.start('Activating Integrator...');
    var connector = this.indexForm.value.ddlConnector;
    var ERP = this.indexForm.value.ddlERP;
    if (connector == "" || connector == null || connector == undefined)
      connector = this._tempConnectorID;
    var JsonBody = {
      Integratorid: this._pkIntegratorID,
      ERP: ERP,
      Customerid: this._customerID,
      Connector: connector,
      SubscriptionType: this.indexForm.value.ddlSubscription,
      LicenseKey: this.indexForm.value.txtLicenseKey,
      ExpiryDate: this.indexForm.value.txtExpiryDate,
      Applicationcode: this.indexForm.value.txtApplicationcode,

      Item_Import: this.chkItemImport,
      Item_Export: this.chkItemeExport,
      Inventory_Import: this.chkInvenImport,
      Inventory_Export: this.chkInveneExport,
      Price_Import: this.chkPriceImport,
      Price_Export: this.chkPriceExport,
      Image_Import: this.chkImageImport,
      Image_Export: this.chkImageExport,
      Orders_Import: this.chkOrdersImport,
      Orders_Export: this.chkOrdersExport,
      Shipments_Import: this.chkShipmentsImport,
      Shipments_Export: this.chkShipmentsExport,
      Refunds_Import: this.chkRefundsImport,
      Refunds_Export: this.chkRefundsExport,
      Returns_Import: this.chkReturnsImport,
      Returns_Export: this.chkReturnsExport,
      Billing_Import: this.chkBillingImport,
      Billing_Export: this.chkBillingExport,
      Cancellation_Import: this.chkCancellationImport,
      Cancellation_Export: this.chkCancellationExport,
      Subsidiary_Export: this.chkCancellationExport,
      Customer_Export: this.chkCancellationExport,

      apiItem_Import: (this.chkItemImport == true) ? (this.getvalueofTextbox("_apiItemImport")) : "",
      apikitItem_Import: (this.chkItemImport == true) ? (this.getvalueofTextbox("_apikitItemImport")) : "",
      apigroupItem_Import: (this.chkItemImport == true) ? (this.getvalueofTextbox("_apigroupItemImport")) : "",
      apimatrixItem_Import: (this.chkItemImport == true) ? (this.getvalueofTextbox("_apimatrixItemImport")) : "",

      apiItem_Export: (this.chkItemeExport == true) ? (this.getvalueofTextbox("_apiItemeExport")) : "",
      apikitItem_Export: (this.chkItemeExport == true) ? (this.getvalueofTextbox("_apiKitItemeExport")) : "",
      apigroupItem_Export: (this.chkItemeExport == true) ? (this.getvalueofTextbox("_apigroupItemeExport")) : "",
      apimatrixItem_Export: (this.chkItemeExport == true) ? (this.getvalueofTextbox("_apimatrixItemeExport")) : "",

      apiInventory_Import: (this.chkInvenImport == true) ? (this.getvalueofTextbox("_apiInvenImport")) : "",
      apiInventory_Export: (this.chkInveneExport == true) ? (this.getvalueofTextbox("_apiInveneExport")) : "",
      apiPrice_Import: (this.chkPriceImport == true) ? (this.getvalueofTextbox("_apiPriceImport")) : "",
      apiPrice_Export: (this.chkPriceExport == true) ? (this.getvalueofTextbox("_apiPriceExport")) : "",
      apiImage_Import: (this.chkImageImport == true) ? (this.getvalueofTextbox("_apiImageImport")) : "",
      apiImage_Export: (this.chkImageExport == true) ? (this.getvalueofTextbox("_apiImageExport")) : "",
      apiOrders_Import: (this.chkOrdersImport == true) ? (this.getvalueofTextbox("_apiOrdersImport")) : "",
      apiOrders_Export: (this.chkOrdersExport == true) ? (this.getvalueofTextbox("_apiOrdersExport")) : "",
      apiShipments_Import: (this.chkShipmentsImport == true) ? (this.getvalueofTextbox("_apiShipmentsImport")) : "",
      apiShipments_Export: (this.chkShipmentsExport == true) ? (this.getvalueofTextbox("_apiShipmentsExport")) : "",
      apiRefunds_Import: (this.chkRefundsImport == true) ? (this.getvalueofTextbox("_apiRefundsImport")) : "",
      apiRefunds_Export: (this.chkRefundsExport == true) ? (this.getvalueofTextbox("_apiRefundsExport")) : "",
      apiReturns_Import: (this.chkReturnsImport == true) ? (this.getvalueofTextbox("_apiReturnsImport")) : "",
      apiReturns_Export: (this.chkReturnsExport == true) ? (this.getvalueofTextbox("_apiReturnsExport")) : "",
      apiBilling_Import: (this.chkBillingImport == true) ? (this.getvalueofTextbox("_apiBillingImport")) : "",
      apiorderinvoices: (this.chkBillingImport == true) ? (this.getvalueofTextbox("_apiorderinvoiceImport")) : "",
      apiordercashsales: (this.chkBillingImport == true) ? (this.getvalueofTextbox("_apiordercashsaleImport")) : "",
      apiBilling_Export: (this.chkBillingExport == true) ? (this.getvalueofTextbox("_apiBillingExport")) : "",
      apiCancellation_Import: (this.chkCancellationImport == true) ? (this.getvalueofTextbox("_apiCancellationImport")) : "",
      apiCancellation_Export: (this.chkCancellationExport == true) ? (this.getvalueofTextbox("_apiCancellationExport")) : ""
    }
    this.connectorService.ActiveIntegrator(JsonBody).subscribe((data: any) => {
      this._showDefaultSubscriptions = false; this._validateSuccess = false; this._subscriptionExists = false;
      if (data.status != "Error") {
        this._defaultValues = { ERP: "", Connector: "", Subscription: "", License: "", AppCode: "" };
        this._message = "Data submitted successfully";
        this.GetCustomerConnectors(this._customerID);
        this.closeAddExpenseModal.nativeElement.click();
        this.ClearAll();
      }
      else {
        this._message = data.errorMessage;
      }
      setTimeout(() => {
        this._message = "";
      }, 3000)
      this.blockUI.stop();
    })
  }

  getvalueofTextbox(id) {
    var Value = ""
    try {
      Value = (<HTMLInputElement>document.getElementById(id)).value
    }
    catch {

    }
    return Value;
  }

  _validateLicenseKeys() {
    this.blockUI.start('Validating keys...');
    if (this.indexForm.value.txtApplicationcode != "" && this.indexForm.value.txtLicenseKey != "") {
      this._message = "";
      this.connectorService.validateLicense(this._customerID, this.indexForm.value.txtApplicationcode, this.indexForm.value.txtLicenseKey).subscribe((data: any) => {
        this.blockUI.stop();
        if (data.status != "Error") {
          // var Date = (data.results.endDate).split('/')[1];
          // Date = (Date.length == 1) ? ("0" + Date) : Date;
          // var Month = (data.results.endDate).split('/')[0];
          // Month = (Month.length == 1) ? ("0" + Month) : Month;
          // var year = (data.results.endDate).split('/')[2];
          // this.Expdate = year + '-' + Month + '-' + Date;
          // this._validateSuccess = true;
          var newdt = new Date();
          var numberOfDaysToAdd = parseInt(data.results.licenseDays);
          newdt.setDate(newdt.getDate() + numberOfDaysToAdd);
          var dd = this.str_pad(newdt.getDate());
          var mm = this.str_pad(newdt.getMonth() + 1);
          var y = newdt.getFullYear();
          this.Expdate = y + '-' + mm + '-' + dd;
          this._validateSuccess = true;
        }
        else {
          this._validateSuccess = false;
          this._message = data.errorMessage;
        }
      }, error => {
        this.blockUI.stop();
      })
    }
    else {
      this.blockUI.stop();
      this._message = "Please enter App code and license key";
    }
  }
  str_pad(n) {
    return String("00" + n).slice(-2);
  }
  isExpired(dates) {
    if (dates) {
      dates = dates.split('/')[2] + '-' + (((dates.split('/')[0]).length == 1) ? ('0' + (dates.split('/')[0])) : (dates.split('/')[0])) + '-' + dates.split('/')[1] + 'T00:00:00';
      let expdate = new Date(dates);
      let today = new Date();
      if (today > expdate) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  onChangeCheck(textboxname) {
    textboxname = "";
    if (this.chkItemImport == true || this.chkItemeExport == true || this.chkPriceImport == true || this.chkPriceExport == true || this.chkInvenImport == true || this.chkInveneExport == true || this.chkImageImport == true || this.chkImageExport == true || this.chkOrdersImport == true || this.chkOrdersExport == true || this.chkBillingImport == true || this.chkBillingExport == true || this.chkShipmentsImport == true || this.chkShipmentsExport == true || this.chkCancellationImport == true || this.chkCancellationExport == true || this.chkRefundsImport == true || this.chkRefundsExport == true || this.chkReturnsImport == true || this.chkReturnsExport == true)
      this._showCustomAPI = true;
    else
      this._showCustomAPI = false;
  }

  viewIntegrator(integratorid) {
    this.blockUI.start('Loading');
    this._subscriptionExists = true;
    this._showDefaultSubscriptions = true;
    this.connectorService.viewIntegrator(integratorid).subscribe((data: any) => {
      console.clear();
      console.log(data);

      this._tempConnectorID = data.results.connectorID;
      this._defaultValues = { ERP: data.results.erp, Connector: data.results.connectorID, Subscription: data.results.subscriptionType, License: data.results.licenseKey, AppCode: data.results.appCode };
      this.Expdate = (data.results.expDate).split('T')[0];
      this.item_Import = data.results.s_Item_Import;
      this.item_Export = data.results.s_Item_Export;
      this.inventory_Import = data.results.s_Inventory_Import;
      this.inventory_Export = data.results.s_Inventory_Export;
      this.price_Import = data.results.s_Price_Import;
      this.price_Export = data.results.s_Price_Export;
      this.image_Import = data.results.s_Image_Import;
      this.image_Export = data.results.s_Image_Export;
      this.orders_Import = data.results.s_Orders_Import;
      this.orders_Export = data.results.s_Orders_Export;
      this.shipments_Import = data.results.s_Shipments_Import;
      this.shipments_Export = data.results.s_Shipments_Export;
      this.refunds_Import = data.results.s_Refunds_Import;
      this.refunds_Export = data.results.s_Refunds_Export;
      this.returns_Import = data.results.s_Returns_Import;
      this.returns_Export = data.results.s_Returns_Export;
      this.billing_Import = data.results.s_Billing_Import;
      this.billing_Export = data.results.s_Billing_Export;
      this.cancellation_Import = data.results.s_Cancellation_Import;
      this.cancellation_Export = data.results.s_Cancellation_Export;
      this.Subsidiary_Export=data.results.s_Subsidiary_Export;
      this.Customer_Export=data.results.s_Customer_Export;

      this.chkItemImport = data.results.add_Item_Import;
      this.chkItemeExport = data.results.add_Item_Export;
      this.chkPriceImport = data.results.add_Price_Import;
      this.chkPriceExport = data.results.add_Price_Export;
      this.chkInvenImport = data.results.add_Inventory_Import;
      this.chkInveneExport = data.results.add_Inventory_Export;
      this.chkImageImport = data.results.add_Image_Import;
      this.chkImageExport = data.results.add_Image_Export;
      this.chkOrdersImport = data.results.add_Orders_Import;
      this.chkOrdersExport = data.results.add_Orders_Export;
      this.chkBillingImport = data.results.add_Billing_Import;
      this.chkBillingExport = data.results.add_Billing_Export;
      this.chkShipmentsImport = data.results.add_Shipments_Import;
      this.chkShipmentsExport = data.results.add_Shipments_Export;
      this.chkCancellationImport = data.results.add_Cancellation_Import;
      this.chkCancellationExport = data.results.add_Cancellation_Export;
      this.chkRefundsImport = data.results.add_Refunds_Import;
      this.chkRefundsExport = data.results.add_Refunds_Export;
      this.chkReturnsImport = data.results.add_Returns_Import;
      this.chkReturnsExport = data.results.add_Returns_Export;
      if (this.chkItemImport == true || this.chkItemeExport == true || this.chkPriceImport == true || this.chkPriceExport == true || this.chkInvenImport == true || this.chkInveneExport == true || this.chkImageImport == true || this.chkImageExport == true || this.chkOrdersImport == true || this.chkOrdersExport == true || this.chkBillingImport == true || this.chkBillingExport == true || this.chkShipmentsImport == true || this.chkShipmentsExport == true || this.chkCancellationImport == true || this.chkCancellationExport == true || this.chkRefundsImport == true || this.chkRefundsExport == true || this.chkReturnsImport == true || this.chkReturnsExport == true)
        this._showCustomAPI = true;
      else
        this._showCustomAPI = false;
      this._cutomApiValues = {
        _aaitem_Import: data.results.api_Item_Import, _akititem_Import: data.results.api_kitItem_Import,
        _agroupitem_Import: data.results.api_groupItem_Import, _amatrixitem_Import: data.results.api_matrixItem_Import,
        _aitem_Export: data.results.api_Item_Export, _akititem_Export: data.results.api_kitItem_Export,
        _agroupitem_Export: data.results.api_groupItem_Export, _amatrixitem_Export: data.results.api_matrixItem_Export,
        _ainventory_Import: data.results.api_Inventory_Import, _ainventory_Export: data.results.api_Inventory_Export,
        _aprice_Import: data.results.api_Price_Import, _aprice_Export: data.results.api_Price_Export,
        _aimage_Import: data.results.api_Image_Import, _aimage_Export: data.results.api_Image_Export,
        _aorders_Import: data.results.api_Orders_Import, _aorders_Export: data.results.api_Orders_Export,
        _ashipments_Import: data.results.api_Shipments_Import, _ashipments_Export: data.results.api_Shipments_Export,
        _arefunds_Import: data.results.api_Refunds_Import, _arefunds_Export: data.results.api_Refunds_Export,
        _areturns_Import: data.results.api_Returns_Import, _areturns_Export: data.results.api_Returns_Export,
        _abilling_Import: data.results.api_Billing_Import, _abilling_Export: data.results.api_Billing_Export,
        _aorderinvocie: data.results.api_orderinvoice, _aordercashsale: data.results.api_ordercashsale,
        _acancellation_Import: data.results.api_Cancellation_Import, _acancellation_Export: data.results.api_Cancellation_Export
      }
      this._pkIntegratorID = data.results.pk_IntegratorID;
      this.blockUI.stop();

      if (data.results.needActivationKey == false) {
        this.falseAllConditions();
        this._showDefaultSubscriptions = false;
        this._subscriptionExists = false;
        this._validateSuccess = true;
      }
    });
  }

  ClearAll() {
    this.indexForm.resetForm({
      ddlERP: "",
      ddlConnector: "",
      ddlSubscription: ""
    });
    this._tempConnectorID = "";
    this._pkIntegratorID = 0;
    this._subscriptionExists = false;
    this._showDefaultSubscriptions = false;
    this._showCustomAPI = false;
    this.Expdate = "";
    this._defaultValues = { ERP: "", Connector: "", Subscription: "", License: "", AppCode: "" };
    this._cutomApiValues = {
      _aaitem_Import: "", _akititem_Import: "", _agroupitem_Import: "", _amatrixitem_Import: "",
      _aitem_Export: "", _akititem_Export: "", _agroupitem_Export: "", _amatrixitem_Export: "",
      _ainventory_Import: "", _ainventory_Export: "", _aprice_Import: "", _aprice_Export: "",
      _aimage_Import: "", _aimage_Export: "", _aorders_Import: "", _aorders_Export: "", _ashipments_Import: "", _ashipments_Export: "",
      _arefunds_Import: "", _arefunds_Export: "", _areturns_Import: "", _areturns_Export: "",
      _abilling_Import: "", _abilling_Export: "", _aorderinvocie: "", _aordercashsale: "",
      _acancellation_Import: "", _acancellation_Export: ""
    }
    this.item_Import = false;
    this.item_Export = false;
    this.inventory_Import = false;
    this.inventory_Export = false;
    this.price_Import = false;
    this.price_Export = false;
    this.image_Import = false;
    this.image_Export = false;
    this.orders_Import = false;
    this.orders_Export = false;
    this.shipments_Import = false;
    this.shipments_Export = false;
    this.refunds_Import = false;
    this.refunds_Export = false;
    this.returns_Import = false;
    this.returns_Export = false;
    this.billing_Import = false;
    this.billing_Export = false;
    this.cancellation_Import = false;
    this.cancellation_Export = false;
    this.Subsidiary_Export = false;
    this.Customer_Export = false;

    this.chkItemImport = false;
    this.chkItemeExport = false;
    this.chkPriceImport = false;
    this.chkPriceExport = false;
    this.chkInvenImport = false;
    this.chkInveneExport = false;
    this.chkImageImport = false;
    this.chkImageExport = false;
    this.chkOrdersImport = false;
    this.chkOrdersExport = false;
    this.chkBillingImport = false;
    this.chkBillingExport = false;
    this.chkShipmentsImport = false;
    this.chkShipmentsExport = false;
    this.chkCancellationImport = false;
    this.chkCancellationExport = false;
    this.chkRefundsImport = false;
    this.chkRefundsExport = false;
    this.chkReturnsImport = false;
    this.chkReturnsExport = false;

    this._apiItemImport = "";
    this._apiItemeExport = "";
    this._apiPriceImport = "";
    this._apiPriceExport = "";
    this._apiInvenImport = "";
    this._apiInveneExport = "";
    this._apiImageImport = "";
    this._apiImageExport = "";
    this._apiOrdersImport = "";
    this._apiOrdersExport = "";
    this._apiBillingImport = "";
    this._apiBillingExport = "";
    this._apiShipmentsImport = "";
    this._apiShipmentsExport = "";
    this._apiCancellationImport = "";
    this._apiCancellationExport = "";
    this._apiRefundsImport = "";
    this._apiRefundsExport = "";
    this._apiReturnsImport = "";
    this._apiReturnsExport = "";

  }

}
