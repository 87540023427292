import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapDataComponent } from '../map-data/map-data.component';
import { HttpClient } from '@angular/common/http';
import { ConnectorsService } from '../servicess/connectors.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ViewChild, ElementRef } from '@angular/core';
import { DataRowOutlet } from '@angular/cdk/table';
import { parse } from 'querystring';


@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  @ViewChild('closeAddExpenseModal') closeAddExpenseModal: ElementRef;
  @ViewChild('closeMarketPlaceModal') closeMarketPlaceModal: ElementRef;
  @ViewChild('closeNetSuiteModal') closeNetSuiteModal: ElementRef;
  @BlockUI() blockUI: NgBlockUI;
  constructor(private router: ActivatedRoute, public dialog: MatDialog, private route: Router, private http: HttpClient, private connectService: ConnectorsService) { }

  advancedSettings: any;
  _orderImportSubscribed: any = false;
  _orderExportSubscribed: any = false;
  _orderImportEnabled: any = false;
  _orderExportEnabled: any = false;
  _orderMissingProcess: any = false;
  _itemfulfilemntProcess: any = false;

  _MarketPlaceFieldMapping: Array<any> = [];
  _EXMarketPlaceFieldMapping: Array<any> = [];
  _orderImportRunning: any = false;
  _orderExportRunning: any = false;
  _MissedOrderRunning: any = false;
  _ItemfulfilmentRunning: any = false;
  _importLockFieldMappingResult: Array<any> = [];
  _importStdFieldMappingResult: Array<any> = [];
  _exportLockFieldMappingResult: Array<any> = [];
  _exportStdFieldMappingResult: Array<any> = [];
  _updatedImportFieldMapping: Array<any> = [];
  _updatedExportFieldMapping: Array<any> = [];
  _connectorData: any;

  _flowNames: Array<any> = [];
  _importFlowName: any;
  _exportFlowName: any;
  _marketPlaceFields: any;
  _SchedulerFrequencyList: any = [];
  _SchedulerStartTimeList: any;
  _SchedulerDaysList: any;
  _importScheduleTime: any = [{ "Date": "", "Frequency": "", "Time": "", "Days": "" }];
  _exportScheduleTime: any = [{ "Date": "", "Frequency": "", "Time": "", "Days": "" }];
  _OrderMissingScheduleTime: any = [{ "Date": "", "Frequency": "", "Time": "", "Days": "", "PastDays": "" }];
  _itemfulfilmentscheduleTime: any = [{ "Date": "", "Frequency": "", "Time": "", "Days": "", "PastDays": "" }];
  _importScheduleTimeDays: any = [];
  _exportScheduleTimeDays: any = [];
  _OrderMissingScheduleTimeDays: any = [];
  _itemfulfilmentscheduleTimeDays: any = [];
  _salesChannels: any = [];

  _arr_ShippingMethodsMapping: Array<any> = [];
  _arrDiscountsMethodMapping: Array<any> = [];
  _arr_PaymentMethodsMapping: Array<any> = [];
  _arr_LocationMethodsMapping: Array<any> = [];
  _arr_TaxesMethodsMapping: Array<any> = [];
  _arr_shipstationStoreMapping: Array<any> = [];
  _arr_CustomFields: Array<any> = [];

  _ShippingMethodsMapping: Array<any> = [];
  _DiscountsMethodMapping: Array<any> = [];
  _MetaFieldMapping: Array<any> = [];
  _PaymentMethodsMapping: Array<any> = [];
  _LocationMethodsMapping: Array<any> = [];
  _TaxesMethodsMapping: Array<any> = [];
  _locationsBysubsidary: Array<any> = [];
  _shipstationStoreMapping: Array<any> = [];
  _customFieldMapping: Array<any> = [];

  _ShipItemMapping: Array<any> = [];
  _arr_ShipItemMapping: Array<any> = [];

  _DefShippingMethodsMapping: any = "";
  _DefPaymentMethodsMapping: any = "";
  _DefLocationMethodsMapping: any = "";
  _DefTaxesMethodsMapping: any = "";
  _DefTaxCodeOrGroup: any = "";
  _DefCustomer: any;
  _giftPurchase: any;
  _defaultShippingItem: any;
  _giftRedeem: any;
  _totalsavingid: any;
  OrderItemPriceLevel: any;
  _ParentCustomer: any;
  _DisItem: any;
  _createDeposit: any = false;
  _taxlinelevel: any = false;
  _taxShippingRates: any = false;
  _exZerolineItem: any = false;

  _DefShippingMethodsMapping_AFN: any = "";
  _DefPaymentMethodsMapping_AFN: any = "";
  _DefLocationMethodsMapping_AFN: any = "";
  _DefTaxesMethodsMapping_AFN: any = "";
  _DefTaxCodeOrGroup_AFN: any = "";
  _DefCustomer_AFN: any;
  _giftPurchase_AFN: any;
  _defaultShippingItem_AFN: any;
  _giftRedeem_AFN: any;
  _ParentCustomer_AFN: any;
  _DisItem_AFN: any;
  _createDeposit_AFN: any = false;
  _TaxLevel_AFN: any = false;
  _taxlinelevel_AFN: any = false;
  _taxShippingRates_AFN: any = false;
  _priceIncludeTax_AFN: any = false;
  _salesChannels_AFN: any = [];
  _DefaddDis_AFN: any = [];
  _orderStatusData_AFN: any = [];
  _importOrder_AFN: any = [];
  _processTax_AFN: any = [];
  _LastOrderNumber_AFN: any = "";

  _priceIncludeTax: any = false;
  _TaxLevel: any = false;
  _datatypeArrname: any;
  _datatypeIndexValue: any;
  _dataTypeValue: any;
  _defaultTypeValue: any;

  _dataMATypeValue: any;
  _dataCPTypeValue: any;
  _dataNSTypeValue: any;
  _defaultNSTypeValue: any;
  _dataCPRecordTypeValue: any;

  _LastOrderNumber: any;
  _DefaddDis: any = "";
  _MappingFieldStatusData: Array<any> = [];
  _orderStatusData: any = "";
  _importOrder: any = "";
  _processTax: any = "";
  _CustomerCreateBy: any = "";
  _importProcessingName: any; _exportProcessingName: any; _OrderMissingProcessingName: any; _itemfulfilment: any;
  _isShopify = false;
  _isWooCommerce = false;
  _isShipstation = false;
  _isAmazon = false;
  _priceLevel: Array<any> = [];
  _currencyLevel: any;
  _ItemSavedSearchDropDown: any = [];
  _serviceAPI: any = {
    orderimport: "OrderImport",
    orderexport: "OrderExport",
    orderProcess: "MissingOrderProcess",
    itemfulfilmet: "IfTrackingUpdate"
  };
  _connectorID: any = "";
  _DefaultDisplayString: any = "Default Values";
  _ShipItemMethodsMapping: any = "";
  _erpName: any = localStorage.getItem('erpname');
  _erpType: any = localStorage.getItem('erptype');
  _isAcumatica: any = false;

  _AC_OrderType: any = "";
  _AC_customerNumber: any = "";
  _AC_CustomerClass: any = "";
  _Ac_CustomerlocationNumber: any = "";
  _Ac_Branch: any = "";

  ngOnInit() {
    const parameters = window.location.href;
    var pieces = parameters.split(/[\s/]+/);
    this._connectorID = (pieces[pieces.length - 1]);
    localStorage.setItem('ActiveLiName', 'Orders');
    // this.blockUI.start('Loading...');
    this.connectorsList()
    this.GetMarketPlaceFields();
    this.GetSchedulerData();
    if (this._erpType == 1) {
      this.GetAdditionalInfo();
      this.GetLocationsBySubsidary();
      this.GetPriceAndCurrency();
    }
    else if (this._erpType == 2) {
      this._isAcumatica = true;
      this.GetAcumaticaShippingMethods();
      this.GetAcumaticaPaymentMethods();
      this.GetAcumaticalocations();
      this.GetAcumaticaTaxes();
    }
    switch ((localStorage.getItem('SelectedconnectorName')).toLowerCase()) {
      case 'shopify':
        {
          this._isShopify = true;
          break;
        }
      case 'woocommerce': {
        this._isWooCommerce = true;
        break;
      }
      case 'shipstation': {
        this._isShipstation = true;
        break;
      }
      case 'amazon': {
        this._isAmazon = true;
        this._DefaultDisplayString = "Default values for MFN";
        break;
      }

      default: {
        break;
      }
    }
  }
  GetAcumaticaShippingMethods() {
    this.connectService.GetAcumaticaShippingMethods(this._connectorID).subscribe((data: any) => {
      var ShipMap = JSON.parse(data.results);
      for (var i = 0; i < ShipMap.value.length; i++) {
        var shipping = { internalId: ShipMap.value[i].ShipVia, name: ShipMap.value[i].ShipVia };
        this._arr_ShippingMethodsMapping.push(shipping);
      }
    })
  }

  GetAcumaticaPaymentMethods() {
    this.connectService.GetAcumaticaPaymentMethods(this._connectorID).subscribe((data: any) => {
      var PayMap = JSON.parse(data.results);
      for (var i = 0; i < PayMap.value.length; i++) {
        var payments = { internalId: PayMap.value[i]._PaymentMethodID, name: PayMap.value[i].Description };
        this._arr_PaymentMethodsMapping.push(payments);
      }
    })
  }

  GetAcumaticalocations() {
    this.connectService.GetAcumaticalocations(this._connectorID).subscribe((data: any) => {
      var LocMap = JSON.parse(data.results);
      for (var i = 0; i < LocMap.length; i++) {
        var locations = { internalid: LocMap[i].WarehouseID.value, name: LocMap[i].WarehouseID.value };
        this._locationsBysubsidary.push(locations);
      }
    })
  }

  GetAcumaticaTaxes() {
    this.connectService.GetAcumaticaTaxes(this._connectorID).subscribe((data: any) => {
      var TaxMap = JSON.parse(data.results);
      for (var i = 0; i < TaxMap.value.length; i++) {
        var taxes = { internalId: TaxMap.value[i].TaxID, name: TaxMap.value[i].TaxID };
        this._arr_TaxesMethodsMapping.push(taxes);
      }
    })
  }

  GetAdditionalInfo() {
    this.connectService.GetOrderAdditionalInfo().subscribe((data: any) => {
      this._arr_ShippingMethodsMapping = data.results.shippingMethods;
      this._arr_PaymentMethodsMapping = data.results.paymentMethods;
      this._arr_LocationMethodsMapping = data.results.locations;
      this._arr_TaxesMethodsMapping = data.results.taxes;
    })
  }
  GetLocationsBySubsidary() {
    this.connectService.Getlocationsbysubsidary(this._connectorID).subscribe((data: any) => {
      if (data.status != "Error") {
        this._locationsBysubsidary = data.results;
      }
    })
  }
  GetSchedulerData() {
    var Result = this.connectService.GetSchedulerListItems();
    this._SchedulerFrequencyList = (Result[0].Frequency);
    this._SchedulerStartTimeList = (Result[0].Time);
    this._SchedulerDaysList = (Result[0].DayList);
  }
  GetMarketPlaceFields() {
    this.connectService.GetItemFieldMapping('SalesOrder', this._connectorID).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.status != "Error") {
        this._marketPlaceFields = data.results.columnStandardFields;
      }
    })
  }
  GetPriceAndCurrency() {
    this.connectService.GetProductAdditionlInfo().subscribe((data: any) => {
      if (data.status != "Error") {
        this._priceLevel = data.results.priceLevels;
      }
    })
  }
  connectorsList() {
    this.EmptyConnectorVariables();
    this.connectService.ListConnector(this._connectorID).subscribe((data: any) => {
      this._flowNames = JSON.parse(data.results[0].flowNames);
      this._importFlowName = (this._flowNames[0]).OrdersImport;
      this._exportFlowName = (this._flowNames[0]).OrdersExport;
      this._serviceAPI = {
        orderimport: (data.results[0].api_Orders_Import != "" && data.results[0].api_Orders_Import != null) ? data.results[0].api_Orders_Import : "OrderImport",
        orderexport: (data.results[0].api_Orders_Export != "" && data.results[0].api_Orders_Export != null) ? data.results[0].api_Orders_Export : "OrderExport",
        orderProcess: "MissingOrderProcess",
        itemfulfilmet: "IfTrackingUpdate"
      };
      var MappingFields = JSON.parse(data.results[0].connectorFields);
      // this._MappingFieldStatusData = JSON.parse(data.results[0].connectorFields);
      try {
        for (var k = 0; k < MappingFields.length; k++) {
          if ((MappingFields[k].RecordType.toUpperCase()) == 'ORDER') {
            this._MarketPlaceFieldMapping.push({ label: MappingFields[k].Label, value: MappingFields[k].Title })
          }
          if ((MappingFields[k].RecordType.toUpperCase()) == 'ORDER' && (MappingFields[k].IsAccessible.toUpperCase()) == "PUBLIC") {
            this._EXMarketPlaceFieldMapping.push({ label: MappingFields[k].Label, value: MappingFields[k].Title })
          }
          if ((MappingFields[k].RecordType.toUpperCase()) == 'FULFILMENT') {
            this._MappingFieldStatusData = (MappingFields[k].RecordValue);
          }
        }
      }
      catch (ex) {

      }
      finally {
        this.GetCustomFields(this._connectorID);
      }

      this._LastOrderNumber = (data.results[0].orderNumber)
      // this._orderImportRunning = (data.results[0].order_ImportRunning)
      // this._orderExportRunning = (data.results[0].order_ExportRunning)
      this._connectorData = data;
      if (data.results[0].s_Orders_Import == true || data.results[0].add_Orders_Import == true)
        this._orderImportSubscribed = true;
      if (data.results[0].s_Orders_Export == true || data.results[0].add_Orders_Export == true)
        this._orderExportSubscribed = true;
      if (data.results[0].orders_Import == true)
        this._orderImportEnabled = true;
      if (data.results[0].orders_Export == true)
        this._orderExportEnabled = true;
      if (data.results[0].orderMissingReprocess == true)
        this._orderMissingProcess = true;
      if (data.results[0].itemFulfilemt_Import == true)
        this._itemfulfilemntProcess = true;
      if (this._connectorData.results[0].orders_ImportFieldsMapping != null && this._connectorData.results[0].orders_ImportFieldsMapping != "") {
        var ItemImportJsonMapping = JSON.parse(this._connectorData.results[0].orders_ImportFieldsMapping);
        var FieldsMapping = JSON.parse(this._connectorData.results[0].orders_ImportFieldsMapping)
        var ItemImportJsonMapping = FieldsMapping;
        for (var i = 0; i < ItemImportJsonMapping.length; i++) {
          if (ItemImportJsonMapping[i].fieldType == 'Lock')
            this._importLockFieldMappingResult.push({ MarketPlace: ItemImportJsonMapping[i].MarketPlace, NetSuite: ItemImportJsonMapping[i].NetSuite, Default: ItemImportJsonMapping[i].Default, Type: "" });
          if (ItemImportJsonMapping[i].fieldType == 'Standard')
            this._importStdFieldMappingResult.push({ label: ItemImportJsonMapping[i].label, MarketPlace: ItemImportJsonMapping[i].MarketPlace, NetSuite: ItemImportJsonMapping[i].NetSuite, Default: ItemImportJsonMapping[i].Default, Type: ItemImportJsonMapping[i].Type });
        }
      }
      if (this._connectorData.results[0].orders_ExportFieldsMapping != null && this._connectorData.results[0].orders_ExportFieldsMapping != "") {
        var ExFieldsMapping = JSON.parse(this._connectorData.results[0].orders_ExportFieldsMapping);
        var ItemExportJsonMapping = ExFieldsMapping;
        for (var i = 0; i < ItemExportJsonMapping.length; i++) {
          if (ItemExportJsonMapping[i].fieldType == 'Lock')
            this._exportLockFieldMappingResult.push({ NetSuite: ItemExportJsonMapping[i].NetSuite, MarketPlace: ItemExportJsonMapping[i].MarketPlace, Default: ItemExportJsonMapping[i].Default, Type: '' });
          if (ItemExportJsonMapping[i].fieldType == 'Standard')
            this._exportStdFieldMappingResult.push({ label: ItemExportJsonMapping[i].label, NetSuite: ItemExportJsonMapping[i].NetSuite, MarketPlace: ItemExportJsonMapping[i].MarketPlace, Default: ItemExportJsonMapping[i].Default, Type: '' });
        }
      }
      this._importStdFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '', Type: '' });
      this._exportStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '', Type: '' });
      if (this._connectorData.results[0].orders_ImportScheduletime != null) {
        this._importScheduleTime = (JSON.parse(this._connectorData.results[0].orders_ImportScheduletime));
        this._importScheduleTimeDays = this._importScheduleTime[0].Days.split(',');
      }
      if (this._connectorData.results[0].orders_ExportScheduletime != null) {
        this._exportScheduleTime = (JSON.parse(this._connectorData.results[0].orders_ExportScheduletime));
        this._exportScheduleTimeDays = this._exportScheduleTime[0].Days.split(',');
      }

      if (this._connectorData.results[0].orderMissingReprocess_Scheduletime != null) {
        this._OrderMissingScheduleTime = (JSON.parse(this._connectorData.results[0].orderMissingReprocess_Scheduletime));
        this._OrderMissingScheduleTimeDays = this._OrderMissingScheduleTime[0].Days.split(',');
      }

      try {
        if (this._connectorData.results[0].itemFulfilemt_ImportScheduletime != null) {
          this._itemfulfilmentscheduleTime = (JSON.parse(this._connectorData.results[0].itemFulfilemt_ImportScheduletime));
          this._itemfulfilmentscheduleTimeDays = this._itemfulfilmentscheduleTime[0].Days.split(',');
        }
      }
      catch {

      }

      if (this._connectorData.results[0].order_AdvanceSettings != null && this._connectorData.results[0].order_AdvanceSettings != "") {
        this._connectorData.results[0].order_AdvanceSettings = (this._connectorData.results[0].order_AdvanceSettings).replace(/Acumatica/g, 'NetSuite');
        this.advancedSettings = JSON.parse(this._connectorData.results[0].order_AdvanceSettings);
        var parsedData = JSON.parse(this._connectorData.results[0].order_AdvanceSettings);
        try {
          this._DefShippingMethodsMapping = parsedData.Default_ShippingMethods;
        }
        catch (ex) {

        }
        try {
          this._salesChannels = (parsedData.SalesChannels).split(',');
        }
        catch (ex) {

        }
        this._DefTaxCodeOrGroup = parsedData.Default_TaxCodeGroup;
        this._DefPaymentMethodsMapping = parsedData.Default_PaymentMethods;
        this._DefLocationMethodsMapping = parsedData.Default_LocationMethods;
        this._DefTaxesMethodsMapping = parsedData.Default_TaxMethods;
        this._DefCustomer = parsedData.DefaultCustomer;
        this._giftPurchase = parsedData.giftPurchase;
        this._giftRedeem = parsedData.giftRedeem;
        this._ParentCustomer = parsedData.ParentCustomer;
        this._DisItem = parsedData.DiscountItem;
        this._createDeposit = parsedData.createDeposit;
        try {
          this._taxShippingRates = parsedData.taxShippingRates;
          this._taxlinelevel = parsedData.taxCodelineItem;
          this._defaultShippingItem = parsedData.defaultShippingItem;
          if (parsedData.ProcessTax)
            this._processTax = parsedData.ProcessTax;
          if (parsedData.CustomerCreateBy)
            this._CustomerCreateBy = parsedData.CustomerCreateBy
          if (parsedData.totalsavingid)
            this._totalsavingid = parsedData.totalsavingid;
          if (parsedData.MetaMappingMethods)
            this._MetaFieldMapping = parsedData.MetaMappingMethods;
          if (parsedData.OrderItemPriceLevel)
            this.OrderItemPriceLevel = parsedData.OrderItemPriceLevel;
          if (parsedData.excludezerolineitem)
            this._exZerolineItem = parsedData.excludezerolineitem;

          if (this._isAcumatica == true) {
            this._AC_OrderType = parsedData.OrderType;
            this._AC_customerNumber = parsedData.CustomerNumberingTemplate;
            this._AC_CustomerClass = parsedData.CustomerClass;
            this._Ac_CustomerlocationNumber = parsedData.CustomerLocationAutoNumber;
            this._Ac_Branch = parsedData.Branch;
          }
        }
        catch (ex) {

        }
        try {
          this._priceIncludeTax = parsedData.priceincludetax;
        }
        catch (ex) {

        }
        this._TaxLevel = parsedData.TaxLineLevel;
        this._ShippingMethodsMapping = parsedData.ShippingMethods;
        this._PaymentMethodsMapping = parsedData.PaymentMethods;
        this._LocationMethodsMapping = parsedData.LocationMethods;
        this._TaxesMethodsMapping = parsedData.TaxMethods;
        try {
          if (parsedData.DiscountMapping)
            this._DiscountsMethodMapping = parsedData.DiscountMapping;
        }
        catch (e) {

        }
        try {
          if (parsedData.ShipItemMapping)
            this._ShipItemMapping = parsedData.ShipItemMapping;
        }
        catch (ex) {
          this._ShipItemMapping.length = 0;
        }
        if (this._isShipstation == true) {
          if (parsedData.ShipStation) {
            this._shipstationStoreMapping = parsedData.ShipStation;
          }
        }

        this._DefaddDis = parsedData.DefaultAddDiscounts;
        this._orderStatusData = (parsedData.Orderstatus);
        this._importOrder = parsedData.ImportOrder;

        try {
          this._DefShippingMethodsMapping_AFN = parsedData.Default_ShippingMethods_AFN;
          this._salesChannels_AFN = (parsedData.SalesChannels_AFN).split(',');
          this._DefTaxCodeOrGroup_AFN = parsedData.Default_TaxCodeGroup_AFN;
          this._DefPaymentMethodsMapping_AFN = parsedData.Default_PaymentMethods_AFN;
          this._DefLocationMethodsMapping_AFN = parsedData.Default_LocationMethods_AFN;
          this._DefTaxesMethodsMapping_AFN = parsedData.Default_TaxMethods_AFN;
          this._DefCustomer_AFN = parsedData.DefaultCustomer_AFN;
          this._giftPurchase_AFN = parsedData.giftPurchase_AFN;
          this._giftRedeem_AFN = parsedData.giftRedeem_AFN;
          this._ParentCustomer_AFN = parsedData.ParentCustomer_AFN;
          this._DisItem_AFN = parsedData.DiscountItem_AFN;
          this._createDeposit_AFN = parsedData.createDeposit_AFN;
          this._taxShippingRates_AFN = parsedData.taxShippingRates_AFN;
          this._taxlinelevel_AFN = parsedData.taxCodelineItem_AFN;
          this._defaultShippingItem_AFN = parsedData.defaultShippingItem_AFN;
          this._processTax_AFN = parsedData.ProcessTax_AFN;
          this._priceIncludeTax_AFN = parsedData.priceincludetax_AFN;
          this._TaxLevel_AFN = parsedData._TaxLevel_AFN;
          this._DefaddDis_AFN = parsedData.ProcessDiscounts_AFN;
          this._orderStatusData_AFN = parsedData.Orderstatus_AFN;
          this._importOrder_AFN = parsedData.ImportOrder_AFN;
        }
        catch (ex) {

        }
      }
      this._ShippingMethodsMapping.push({ NetSuite: "", MarketPlace: "", Carrier: "" });
      this._DiscountsMethodMapping.push({ NetSuite: "", MarketPlace: "" });
      this._MetaFieldMapping.push({ NetSuite: "", label: "", NetSuite_DataType: "", NetSuite_CompareTo: "", MarketPlace: "", MarketPlace_DataType: "", MarketPlace_CompareWith: "", RecordType: "" });
      this._PaymentMethodsMapping.push({ NetSuite: "", MarketPlace: "" });
      this._LocationMethodsMapping.push({ NetSuite: "", MarketPlace: "" });
      this._TaxesMethodsMapping.push({ NetSuite: "", MarketPlace: "" });
      this._ShipItemMapping.push({ NetSuite: "", MarketPlace: "" });
      if (this._isShipstation == true) {
        this._shipstationStoreMapping.push({ NetSuite: "", MarketPlace: "" })
      }
    })
  }
  GetCustomFields(connectorID) {
    this.connectService.GetCustomFields(connectorID).subscribe((data: any) => {
      for (var i = 0; i < data.results.length; i++) {
        this._EXMarketPlaceFieldMapping.push({ label: data.results[i].fieldName, value: data.results[i].scriptId })
        this._customFieldMapping.push({ FieldName: data.results[i].fieldName, ScriptId: data.results[i].scriptId })
      }
      this._customFieldMapping.push({ FieldName: "", ScriptId: "" })
    })
  }
  UpdateFlowStatus(FlowUpdateVariable) {
    this.connectService.UpdateFlowStatusByConnector(FlowUpdateVariable, this._connectorID).subscribe((data: any) => {

    })
  }
  _OnChangeStandardImportFieldMapping(index) {
    var index_id = (this._importStdFieldMappingResult.length) - 1;
    var SiD = "StdSrc_" + index_id;
    var DiD = "StdDst_" + index_id;
    var DfD = "DFTStd_" + index_id;
    var S_e = (document.getElementById("" + SiD)) as HTMLSelectElement;
    var S_sel = S_e.selectedIndex;
    var S_opt = S_e.options[S_sel];
    var ExpValue = (<HTMLInputElement>document.getElementById(DiD)).value;
    var DestValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (ExpValue.toLowerCase().includes('custbody') || ExpValue.toLowerCase().includes('custco')) {
      var cogid = "coggSrc_" + index_id;
      document.getElementById(cogid).style.display = "block";
    }
    else {
      if (ExpValue != "" && (((<HTMLOptionElement>S_opt).value) != "" || DestValue != "")) {
        this._importStdFieldMappingResult.push({ MarketPlace: '', NetSuite: '', Default: '', Type: '' });
      }
    }
    // CheckCustomValue
    var Dval = "StdDst_" + index;
    var ExpValInd = (<HTMLInputElement>document.getElementById(Dval)).value;
    var cogindex = "coggSrc_" + index;
    if (ExpValInd.toLowerCase().includes('custbody') || ExpValInd.toLowerCase().includes('cust')) {
      document.getElementById(cogindex).style.display = "block";
    }
    else {
      document.getElementById(cogindex).style.display = "none";
    }
  }

  _OnChangeStandardExportFieldMapping() {
    var index_id = (this._exportStdFieldMappingResult.length) - 1;
    var SiD = "ExStdSrc_" + index_id;
    var DiD = "ExStdDst_" + index_id;
    var DfD = "DFTStdEx_" + index_id;
    var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
    var D_sel = D_e.selectedIndex;
    var D_opt = D_e.options[D_sel];
    var SourceValue = (<HTMLInputElement>document.getElementById(SiD)).value;
    var DestValue = ((<HTMLOptionElement>D_opt).value)
    var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
    if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
      this._exportStdFieldMappingResult.push({ NetSuite: '', MarketPlace: '', Default: '', Type: '' });
    }
  }

  PostImportFieldMapping() {
    this._updatedImportFieldMapping.length = 0
    for (var j = 0; j < this._importLockFieldMappingResult.length; j++) {
      var SiD = "SpnImpLockSrc_" + j;
      var DiD = "SpnImpLockDes_" + j;
      var DfD = "SpnImLockDef_" + j;
      var SIMLockValue = (<HTMLInputElement>document.getElementById(SiD)).innerHTML
      var DefIMLockValue = (<HTMLInputElement>document.getElementById(DiD)).value;
      var DesIMLockValue = (<HTMLInputElement>document.getElementById(DfD)).innerHTML
      this._updatedImportFieldMapping.push({ MarketPlace: SIMLockValue, NetSuite: DefIMLockValue, Default: DesIMLockValue, fieldType: 'Lock' })
    }
    for (var i = 0; i < this._importStdFieldMappingResult.length; i++) {
      var SiD = "StdSrc_" + i;
      var DiD = "StdDst_" + i;
      var DfD = "DFTStd_" + i;
      var S_e = (document.getElementById("" + SiD)) as HTMLSelectElement;
      var S_sel = S_e.selectedIndex;
      var S_opt = S_e.options[S_sel];
      var ExpValue = this.GetValueFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DestValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (ExpValue != "" && (((<HTMLOptionElement>S_opt).value) != "" || DestValue != "")) {
        if ((this.NetSuiteFieldExists(this._updatedImportFieldMapping, ExpValue)) == false)
          this._updatedImportFieldMapping.push({ MarketPlace: ((<HTMLOptionElement>S_opt).value), label: (<HTMLInputElement>document.getElementById(DiD)).value, NetSuite: ExpValue, Default: DestValue, Type: this._importStdFieldMappingResult[i].Type, fieldType: 'Standard', DataType: DataType })
      }
    }
    this.connectService.Srv_PostImportFieldMapping(this._updatedImportFieldMapping, "orders_ImportFieldsMapping", this._connectorID).subscribe((data: any) => {
      this.connectorsList();
    })
  }
  PostExportFieldMapping() {
    this._updatedExportFieldMapping.length = 0;
    for (var i = 0; i < this._exportStdFieldMappingResult.length; i++) {
      var SiD = "ExStdSrc_" + i;
      var DiD = "ExStdDst_" + i;
      var DfD = "DFTStdEx_" + i;
      var D_e = (document.getElementById("" + DiD)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var SourceValue = this.GetValueFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(SiD)).value));
      var DataType = this.GetFieldTypeFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(SiD)).value));
      var DestValue = ((<HTMLOptionElement>D_opt).value)
      var DefaultValue = (<HTMLInputElement>document.getElementById(DfD)).value;
      if (DestValue != "" && (SourceValue != "" || DefaultValue != "")) {
        if ((this.MarketPlaceFieldExists(this._updatedExportFieldMapping, DestValue)) == false)
          this._updatedExportFieldMapping.push({ label: (<HTMLInputElement>document.getElementById(SiD)).value, NetSuite: SourceValue, MarketPlace: DestValue, Default: DefaultValue, fieldType: 'Standard', DataType: DataType })
      }
    }
    for (var k = 0; k < this._exportLockFieldMappingResult.length; k++) {
      var SiD = "SpnExpLockSrc_" + k;
      var DiD = "SpnExpLockDes_" + k;
      var DfD = "SpnExpLockDef_" + k;
      var SIMLockValue = ((<HTMLInputElement>document.getElementById(SiD)).value);
      var DefIMLockValue = this.GetValueFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(DiD)).value));
      var DesIMLockValue = (<HTMLInputElement>document.getElementById(DfD)).innerHTML
      this._updatedExportFieldMapping.push({ NetSuite: SIMLockValue, label: ((<HTMLInputElement>document.getElementById(DiD)).value), MarketPlace: DefIMLockValue, Default: DesIMLockValue, fieldType: 'Lock' })
    }
    this.connectService.Srv_PostImportFieldMapping(this._updatedExportFieldMapping, "orders_ExportFieldsMapping", this._connectorID).subscribe((data: any) => {
      this.connectorsList();
    })
  }

  PostIISchedulerJSON() {
    var StandardItemImport = [];
    StandardItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_IMIS_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_IMIS_Frequency"), "Time": this.GetSelectedValueFromId("_IMIS_Time"), "Days": this.GetSelectedMultipleValuesFromID("_IMIS_Days") })
    this.connectService.Srv_PostImportFieldMapping(StandardItemImport, "Orders_ImportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }
  PostIESchedulerJSON() {
    var StandardItemImport = [];
    StandardItemImport.push({ "Date": (<HTMLInputElement>document.getElementById("_IEIS_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_IEIS_Frequency"), "Time": this.GetSelectedValueFromId("_IEIS_Time"), "Days": this.GetSelectedMultipleValuesFromID("_IEIS_Days") })
    this.connectService.Srv_PostImportFieldMapping(StandardItemImport, "Orders_ExportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }
  PostOMRSchedulerJSON() {
    var orderMissingProcess = [];
    orderMissingProcess.push({ "Date": (<HTMLInputElement>document.getElementById("_OrderMissing_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_OrderMissing_Frequency"), "Time": this.GetSelectedValueFromId("_OrderMissing_Time"), "Days": this.GetSelectedMultipleValuesFromID("_OrderMissing_Days"), "PastDays": (<HTMLInputElement>document.getElementById("_OrderMissing_LastDays")).value })
    this.connectService.Srv_PostImportFieldMapping(orderMissingProcess, "OrderMissing_ScheduleTime", this._connectorID).subscribe((data: any) => {

    })
  }

  PostIFTrackingSchedulerJSON() {
    var ItemFulfilemt_ImportScheduletime = [];
    ItemFulfilemt_ImportScheduletime.push({ "Date": (<HTMLInputElement>document.getElementById("_IF_ScheduleFrom")).value, "Frequency": this.GetSelectedValueFromId("_IF_Frequency"), "Time": this.GetSelectedValueFromId("_IF_Time"), "Days": this.GetSelectedMultipleValuesFromID("_IF_Days") })
    this.connectService.Srv_PostImportFieldMapping(ItemFulfilemt_ImportScheduletime, "ItemFulfilemt_ImportScheduletime", this._connectorID).subscribe((data: any) => {

    })
  }

  _OnChangeShippingMethods() {
    var index_id = (this._ShippingMethodsMapping.length) - 1;
    var NSID = "_SMNS_" + index_id;
    var MPID = "_SMMP_" + index_id;
    var NS = this.GetSelectedValueFromId(NSID);
    var MP = (<HTMLInputElement>document.getElementById(MPID)).value;
    if (NS != "" && MP != "") {
      this._ShippingMethodsMapping.push({ NetSuite: "", MarketPlace: "" });
    }
  }
  _OnChangeDiscountMethods() {
    var index_id = (this._DiscountsMethodMapping.length) - 1;
    var NSID = "_DSNF_" + index_id;
    var MPID = "_DSMP_" + index_id;
    var NS = (<HTMLInputElement>document.getElementById(NSID)).value;
    var MP = (<HTMLInputElement>document.getElementById(MPID)).value;
    if (NS != "" && MP != "") {
      this._DiscountsMethodMapping.push({ NetSuite: "", MarketPlace: "" });
    }
  }

  _OnChangeMetaMappingMethods() {
    var index_id = (this._MetaFieldMapping.length) - 1;
    var NSID = "_NSP_" + index_id;
    var MPID = "_MMP_" + index_id;
    var NS = (<HTMLInputElement>document.getElementById(NSID)).value;
    var MP = (<HTMLInputElement>document.getElementById(MPID)).value;
    if (NS != "" && MP != "") {
      this._MetaFieldMapping.push({ NetSuite: "", label: "", NetSuite_DataType: "", NetSuite_CompareTo: "", MarketPlace: "", MarketPlace_DataType: "", MarketPlace_CompareWith: "", RecordType: "" });
    }
  }

  _OnChangePaymentMethods() {
    var index_id = (this._PaymentMethodsMapping.length) - 1;
    var NSID = "_PMNS_" + index_id;
    var MPID = "_PMMP_" + index_id;
    var NS = this.GetSelectedValueFromId(NSID);
    var MP = (<HTMLInputElement>document.getElementById(MPID)).value;
    if (NS != "" && MP != "") {
      this._PaymentMethodsMapping.push({ NetSuite: "", MarketPlace: "" });
    }
  }
  _OnChangeLocationMethods() {
    var index_id = (this._LocationMethodsMapping.length) - 1;
    var NSID = "_LMNS_" + index_id;
    var MPID = "_LMMP_" + index_id;
    var NS = this.GetSelectedValueFromId(NSID);
    var MP = (<HTMLInputElement>document.getElementById(MPID)).value;
    if (NS != "" && MP != "") {
      this._LocationMethodsMapping.push({ NetSuite: "", MarketPlace: "" });
    }
  }
  _OnChangeTaxMethods() {
    var index_id = (this._TaxesMethodsMapping.length) - 1;
    var NSID = "_TMNS_" + index_id;
    var MPID = "_TMMP_" + index_id;
    var NS = this.GetSelectedValueFromId(NSID);
    var MP = (<HTMLInputElement>document.getElementById(MPID)).value;
    if (NS != "" && MP != "") {
      this._TaxesMethodsMapping.push({ NetSuite: "", MarketPlace: "" });
    }
  }
  _OnChangeSITMethods() {
    var index_id = (this._ShipItemMapping.length) - 1;
    var NSID = "_SITNS_" + index_id;
    var MPID = "_SITMP_" + index_id;
    var NS = (<HTMLInputElement>document.getElementById(NSID)).value;
    var MP = (<HTMLInputElement>document.getElementById(MPID)).value;
    if (NS != "" && MP != "") {
      this._ShipItemMapping.push({ NetSuite: "", MarketPlace: "" });
    }
  }
  _OnChangeshipstationMethods() {
    var index_id = (this._shipstationStoreMapping.length) - 1;
    var NSID = "_ship_" + index_id;
    var MPID = "_shipmp_" + index_id;
    var NS = this.GetSelectedValueFromId(NSID);
    var MP = (<HTMLInputElement>document.getElementById(MPID)).value;
    if (NS != "" && MP != "") {
      this._shipstationStoreMapping.push({ NetSuite: "", MarketPlace: "" });
    }
  }
  _OnChangeCustomMethods() {
    var index_id = (this._customFieldMapping.length) - 1;
    var FName = "_FieldName_" + index_id;
    var SID = "_ScriptID_" + index_id;
    var FieldName = (<HTMLInputElement>document.getElementById(FName)).value;
    var ScriptID = (<HTMLInputElement>document.getElementById(SID)).value;
    if (FieldName != "" && ScriptID != "") {
      this._customFieldMapping.push({ FieldName: "", ScriptId: "" })
    }
  }

  convertAndReplaceToJSON(JsonParam) {
    var stringdata = JSON.stringify(JsonParam);
    if (stringdata.includes('NetSuite')) {
      stringdata = stringdata.replace(/NetSuite/g, 'Acumatica');
      JsonParam = JSON.parse(stringdata);
    }
    return JsonParam;
  }

  postAdditionalSettings() {
    this.blockUI.start('Data Updating...');
    var ShippingMethods = [];
    for (var i = 0; i < this._ShippingMethodsMapping.length; i++) {
      var NSID = "_SMNS_" + i;
      var MPID = "_SMMP_" + i;
      var SHCH = "_SMSH_" + i;
      var NS = this.GetSelectedValueFromId(NSID);
      var MP = (<HTMLInputElement>document.getElementById(MPID)).value;
      if (this._isShipstation == true) {
        var SH = (<HTMLInputElement>document.getElementById(SHCH)).value;
        if (NS != "" && MP != "") {
          ShippingMethods.push({ NetSuite: NS, MarketPlace: MP, Carrier: SH })
        }
      }
      else {
        if (NS != "" && MP != "") {
          ShippingMethods.push({ NetSuite: NS, MarketPlace: MP })
        }
      }
    }

    var DiscountMethods = [];
    for (var i = 0; i < this._DiscountsMethodMapping.length; i++) {
      var NSID = "_DSNF_" + i;
      var MPID = "_DSMP_" + i;
      var NS = (<HTMLInputElement>document.getElementById(NSID)).value;
      var MP = (<HTMLInputElement>document.getElementById(MPID)).value;
      if (NS != "" && MP != "") {
        DiscountMethods.push({ NetSuite: NS, MarketPlace: MP })
      }
    }

    var MetaMappingMethods = [];
    for (var i = 0; i < this._MetaFieldMapping.length; i++) {
      var NSID = "_NSP_" + i;
      var MPID = "_MMP_" + i;
      var NS = (<HTMLInputElement>document.getElementById(NSID)).value;
      var lbl = this.GetValueFromArray(this._marketPlaceFields, ((<HTMLInputElement>document.getElementById(NSID)).value));
      var MP = (<HTMLInputElement>document.getElementById(MPID)).value;
      if (NS != "" && MP != "") {
        MetaMappingMethods.push({ NetSuite: NS, Label: lbl, NetSuite_DataType: this._MetaFieldMapping[i].NetSuite_DataType, NetSuite_CompareTo: this._MetaFieldMapping[i].NetSuite_CompareTo, MarketPlace: MP, MarketPlace_DataType: this._MetaFieldMapping[i].MarketPlace_DataType, MarketPlace_CompareWith: this._MetaFieldMapping[i].MarketPlace_CompareWith, RecordType: this._MetaFieldMapping[i].RecordType });
      }
      console.clear();
      console.log(MetaMappingMethods);
    }

    var PaymentMethods = [];
    for (var i = 0; i < this._PaymentMethodsMapping.length; i++) {
      var NSID = "_PMNS_" + i;
      var MPID = "_PMMP_" + i;
      var NS = this.GetSelectedValueFromId(NSID);
      var MP = (<HTMLInputElement>document.getElementById(MPID)).value;
      if (NS != "" && MP != "") {
        PaymentMethods.push({ NetSuite: NS, MarketPlace: MP })
      }
    }

    var LocationMethods = [];
    for (var i = 0; i < this._LocationMethodsMapping.length; i++) {
      var NSID = "_LMNS_" + i;
      var MPID = "_LMMP_" + i;
      var NS = this.GetSelectedValueFromId(NSID);
      var MP = (<HTMLInputElement>document.getElementById(MPID)).value;
      if (NS != "" && MP != "") {
        LocationMethods.push({ NetSuite: NS, MarketPlace: MP })
      }
    }

    var TaxMethods = [];
    for (var i = 0; i < this._TaxesMethodsMapping.length; i++) {
      var NSID = "_TMNS_" + i;
      var MPID = "_TMMP_" + i;
      var NS = this.GetSelectedValueFromId(NSID);
      var MP = (<HTMLInputElement>document.getElementById(MPID)).value;
      if (NS != "" && MP != "") {
        TaxMethods.push({ NetSuite: NS, MarketPlace: MP })
      }
    }

    var ShipItemMethods = [];
    for (var i = 0; i < this._ShipItemMapping.length; i++) {
      var NSID = "_SITNS_" + i;
      var MPID = "_SITMP_" + i;
      var NS = (<HTMLInputElement>document.getElementById(NSID)).value;
      var MP = (<HTMLInputElement>document.getElementById(MPID)).value;
      if (NS != "" && MP != "") {
        ShipItemMethods.push({ NetSuite: NS, MarketPlace: MP })
      }
    }
    var shipstation = []; var CustomFields = [];
    if (this._isShipstation == true) {
      for (var i = 0; i < this._shipstationStoreMapping.length; i++) {
        var NSID = "_ship_" + i;
        var MPID = "_shipmp_" + i;
        var NS = this.GetSelectedValueFromId(NSID);
        var MP = (<HTMLInputElement>document.getElementById(MPID)).value;
        if (NS != "" && MP != "") {
          shipstation.push({ NetSuite: NS, MarketPlace: MP })
        }
      }

      for (var i = 0; i < this._customFieldMapping.length; i++) {
        var Fname = "_FieldName_" + i;
        var Sid = "_ScriptID_" + i;
        var fieldName = (<HTMLInputElement>document.getElementById(Fname)).value;
        var scriptID = (<HTMLInputElement>document.getElementById(Sid)).value;
        if (fieldName != "" && scriptID != "") {
          CustomFields.push({ FieldName: fieldName, ScriptId: scriptID })
        }
      }
    }


    var AdditionalSettings = {
      "DiscountMapping": DiscountMethods,
      "ShippingMethods": ShippingMethods,
      "PaymentMethods": PaymentMethods,
      "LocationMethods": LocationMethods,
      "TaxMethods": TaxMethods,
      "ShipStation": shipstation,
      "TaxLineLevel": this._TaxLevel,
      "ShipItemMapping": ShipItemMethods,
      "MetaMappingMethods": MetaMappingMethods,
      "Default_ShippingMethods": this.GetSelectedValueFromId("_ddl_DefShipping"),
      "Default_TaxCodeGroup": this.GetSelectedValueFromId("_ddl_DefTaxCodeGroup"),
      "Default_PaymentMethods": this.GetSelectedValueFromId("_ddl_DefPayment"),
      "Default_LocationMethods": this.GetSelectedValueFromId("_ddl_DefLocation"),
      "Default_TaxMethods": (<HTMLInputElement>document.getElementById("txt_DefaultTaxItem")).value,
      "DefaultCustomer": (<HTMLInputElement>document.getElementById("txt_DefaultCustomer")).value,
      "DiscountItem": (<HTMLInputElement>document.getElementById("txt_DefaultItem")).value,
      "ParentCustomer": (<HTMLInputElement>document.getElementById("txt_ParentCustomer")).value,
      "createDeposit": this._createDeposit,
      "taxCodelineItem": this._taxlinelevel,
      "taxShippingRates": this._taxShippingRates,
      "excludezerolineitem": this._exZerolineItem,
      "priceincludetax": this._priceIncludeTax,
      "giftPurchase": (<HTMLInputElement>document.getElementById("txt_cardpurchase")).value,
      "defaultShippingItem": (<HTMLInputElement>document.getElementById("txt_DefaultShippingItem")).value,
      "giftRedeem": (<HTMLInputElement>document.getElementById("txt_cardredeem")).value,
      "DefaultAddDiscounts": this.GetSelectedValueFromId("_ddl_adddis"),
      "Orderstatus": this.GetSelectedValueFromId("_ddl_orderStatus"),
      "ImportOrder": this.GetSelectedValueFromId("_ddl_importorder"),
      "ProcessTax": this.GetSelectedValueFromId("_ddl_processTax"),
      "CustomerCreateBy": this.GetSelectedValueFromId("_ddl_customercreation"),
      "SalesChannels": this.GetSelectedMultipleValuesFromID("_ddl_Saleschannels"),
      "totalsavingid": (<HTMLInputElement>document.getElementById("txt_totalSavingId")).value,
      "OrderItemPriceLevel": this.GetSelectedValueFromId("_ddlSalePricelevelExport")
    }

    if (this._isAcumatica == true) {
      AdditionalSettings["OrderType"] = (<HTMLInputElement>document.getElementById("txt_AC_OrderType")).value;
      AdditionalSettings["CustomerNumberingTemplate"] = (<HTMLInputElement>document.getElementById("txt_AC_CustomerNumberingTemplate")).value;
      AdditionalSettings["CustomerClass"] = (<HTMLInputElement>document.getElementById("txt_AC_CustomerClass")).value;
      AdditionalSettings["CustomerLocationAutoNumber"] = (<HTMLInputElement>document.getElementById("txt_AC_CustomerLocationNumber")).value;
      AdditionalSettings["Branch"] = (<HTMLInputElement>document.getElementById("txt_AC_Branch")).value;

      AdditionalSettings.DiscountMapping = this.convertAndReplaceToJSON(AdditionalSettings.DiscountMapping);
      AdditionalSettings.ShippingMethods = this.convertAndReplaceToJSON(AdditionalSettings.ShippingMethods);
      AdditionalSettings.PaymentMethods = this.convertAndReplaceToJSON(AdditionalSettings.PaymentMethods);
      AdditionalSettings.LocationMethods = this.convertAndReplaceToJSON(AdditionalSettings.LocationMethods);
      AdditionalSettings.TaxMethods = this.convertAndReplaceToJSON(AdditionalSettings.TaxMethods);

    }
    if (this._isAmazon) {
      AdditionalSettings["Default_ShippingMethods_AFN"] = this.GetSelectedValueFromId("_ddl_DefShipping_AFN");
      AdditionalSettings["Default_TaxCodeGroup_AFN"] = this.GetSelectedValueFromId("_ddl_DefTaxCodeGroup_AFN");
      AdditionalSettings["Default_PaymentMethods_AFN"] = this.GetSelectedValueFromId("_ddl_DefPayment_AFN");
      AdditionalSettings["Default_LocationMethods_AFN"] = this.GetSelectedValueFromId("_ddl_DefLocation_AFN");
      AdditionalSettings["ProcessDiscounts_AFN"] = this.GetSelectedValueFromId("_ddl_adddis_AFN");
      AdditionalSettings["Default_TaxMethods_AFN"] = (<HTMLInputElement>document.getElementById("txt_DefaultTaxItem_AFN")).value;
      AdditionalSettings["DefaultCustomer_AFN"] = (<HTMLInputElement>document.getElementById("txt_DefaultCustomer_AFN")).value;
      AdditionalSettings["DiscountItem_AFN"] = (<HTMLInputElement>document.getElementById("txt_DefaultItem_AFN")).value;
      AdditionalSettings["ParentCustomer_AFN"] = (<HTMLInputElement>document.getElementById("txt_ParentCustomer_AFN")).value;
      AdditionalSettings["createDeposit_AFN"] = this._createDeposit_AFN;
      AdditionalSettings["_TaxLevel_AFN"] = this._TaxLevel_AFN;
      AdditionalSettings["taxCodelineItem_AFN"] = this._taxlinelevel_AFN;
      AdditionalSettings["taxShippingRates_AFN"] = this._taxShippingRates_AFN;
      AdditionalSettings["priceincludetax_AFN"] = this._priceIncludeTax_AFN;
      AdditionalSettings["giftPurchase_AFN"] = (<HTMLInputElement>document.getElementById("txt_cardpurchase_AFN")).value;
      AdditionalSettings["defaultShippingItem_AFN"] = (<HTMLInputElement>document.getElementById("txt_DefaultShippingItem_AFN")).value;
      AdditionalSettings["giftRedeem_AFN"] = (<HTMLInputElement>document.getElementById("txt_cardredeem_AFN")).value;
      AdditionalSettings["DefaultAddDiscounts_AFN"] = this.GetSelectedValueFromId("_ddl_adddis_AFN");
      AdditionalSettings["Orderstatus_AFN"] = this.GetSelectedValueFromId("_ddl_orderStatus_AFN");
      AdditionalSettings["ImportOrder_AFN"] = this.GetSelectedValueFromId("_ddl_importorder_AFN");
      AdditionalSettings["ProcessTax_AFN"] = this.GetSelectedValueFromId("_ddl_processTax_AFN");
      AdditionalSettings["SalesChannels_AFN"] = this.GetSelectedMultipleValuesFromID("_ddl_Saleschannels_AFN");
    }
    this.connectService.Srv_PostImportFieldMapping(AdditionalSettings, "Order_AdvanceSettings", this._connectorID).subscribe((data: any) => {

    }, (error) => {

    })

    this.connectService.UpdateCustomFields(this._connectorID, CustomFields).subscribe((data: any) => {
      //location.reload();
    }, (error) => {

    })
    var lastUpdated = (<HTMLInputElement>document.getElementById("txt_lastOrderNumber")).value;
    if (lastUpdated != "" && lastUpdated != null && lastUpdated != undefined) {
      this.connectService.Srv_UpdateOrderNumber(lastUpdated, this._connectorID).subscribe((data: any) => {

      }, (error) => {

      })
    }
    setTimeout(() => {
      this.blockUI.stop();
    }, 1000);

  }
  GetValueFromArray(JsonArray, Value) {
    if (Value) {
      var index = -1;
      var filteredObj = JsonArray.find(function (item, i) {
        if ((item.fieldName).toUpperCase() === Value.toUpperCase()) {
          index = i;
        }
      });
      if (index == -1) {
        return Value;
      }
      else {
        return JsonArray[index].scriptId;
      }
    }
    else {
      return "";
    }
  }
  GetFieldTypeFromArray(JsonArray, Value) {
    if (Value) {
      var index = -1;
      var filteredObj = JsonArray.find(function (item, i) {
        if ((item.fieldName).toUpperCase() === Value.toUpperCase()) {
          index = i;
        }
      });
      if (index == -1) {
        return Value;
      }
      else {
        return JsonArray[index].fieldType;
      }
    }
    else {
      return "";
    }
  }
  GetSelectedValueFromId(ID) {
    try {
      var D_e = (document.getElementById(ID)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var DestValue = ((<HTMLOptionElement>D_opt).value);
      return DestValue;
    }
    catch (e) {
      return "";
    }
  }
  GetSelectedTextFromId(ID) {
    try {
      var D_e = (document.getElementById(ID)) as HTMLSelectElement;
      var D_sel = D_e.selectedIndex;
      var D_opt = D_e.options[D_sel];
      var DestValue = ((<HTMLOptionElement>D_opt).text)
      return DestValue;
    }
    catch (e) {
      return "";
    }
  }
  GetSelectedMultipleValuesFromID(ID) {
    let selectElement = (document.getElementById(ID)) as HTMLSelectElement;
    let selectedValues = Array.from(selectElement.selectedOptions).map(option => option.value);
    return ((selectedValues).toString());
  }
  RemoveDivById(divString: any, i) {
    if (divString.length - 1 == i) {

    }
    else {
      var ans = confirm('Are you sure to remove the field?');
      if (ans) {
        divString.splice(i, 1);
      }
    }
  }
  CheckInArray(Arr, Value) {
    Arr = Arr.map(String);
    if (Arr.indexOf(Value.toString()) > -1) {
      return true;
    }
    return false;
  }
  RunIntegrator(ApiMethodName, RunningMethodName, ProcessingName) {
    this['' + RunningMethodName] = true;
    this['' + ProcessingName] = 'Initiated...';
    setTimeout(() => {
      setTimeout(() => {
        this['' + RunningMethodName] = false;
      }, 3000)
      this['' + ProcessingName] = 'Processing...';
    }, 3000)
    this.connectService.RunIntegrator(ApiMethodName, this._connectorID).subscribe((data: any) => {

    })
  }
  NetSuiteFieldExists(Arr, value) {
    if (value != "") {
      return Arr.some(function (el) {
        var status = (el.NetSuite === value);
        return status;
      });
    }
    else {
      return false;
    }
  }
  MarketPlaceFieldExists(Arr, value) {
    if (value != "") {
      return Arr.some(function (el) {
        var status = (el.MarketPlace === value);
        return status;
      });
    }
    else {
      return false;
    }
  }
  EmptyConnectorVariables() {
    this._MarketPlaceFieldMapping.length = 0;
    this._EXMarketPlaceFieldMapping.length = 0;
    this._importLockFieldMappingResult.length = 0;
    this._importStdFieldMappingResult.length = 0;
    this._exportLockFieldMappingResult.length = 0;
    this._exportStdFieldMappingResult.length = 0;
    this._ShippingMethodsMapping.length = 0;
    this._DiscountsMethodMapping.length = 0;
    this._PaymentMethodsMapping.length = 0;
    this._LocationMethodsMapping.length = 0;
    this._TaxesMethodsMapping.length = 0;
    this._ShipItemMapping.length = 0;
  }
  dataTypesPopupInitialize(Arr, index) {
    this._datatypeArrname = Arr;
    this._datatypeIndexValue = index;
    try {
      this._dataTypeValue = Arr[index].Type;
      this._defaultTypeValue = Arr[index].Default;
    }
    catch (exception) {

    }
  }
  MetaMRPopupInitialize(Arr, index) {
    this._datatypeArrname = Arr;
    this._datatypeIndexValue = index;

    try {
      this._dataMATypeValue = Arr[index].MarketPlace_DataType;
      this._dataCPTypeValue = Arr[index].MarketPlace_CompareWith;
      this._dataCPRecordTypeValue = Arr[index].RecordType
    }
    catch (exception) {

    }
  }

  MetaNSPopupInitialize(Arr, index) {
    this._datatypeArrname = Arr;
    this._datatypeIndexValue = index;
    try {
      this._dataNSTypeValue = Arr[index].NetSuite_DataType;
      this._defaultNSTypeValue = Arr[index].NetSuite_CompareTo;
    }
    catch (exception) {

    }
  }

  onSubmitPopupDetails() {
    this.closeAddExpenseModal.nativeElement.click();
    var DfD = "DFTStd_" + this._datatypeIndexValue;
    if (this._defaultTypeValue)
      (<HTMLInputElement>document.getElementById(DfD)).value = this._defaultTypeValue;
    var arrName = this._datatypeArrname;
    arrName[this._datatypeIndexValue].Type = this._dataTypeValue;
    if (this._datatypeIndexValue == (arrName.length - 1))
      arrName.push({ NetSuite: '', MarketPlace: '', Default: '', Type: '' });
  }

  onSubmitNSPopupDetails() {
    this.closeNetSuiteModal.nativeElement.click();
    var arrName = this._datatypeArrname;
    arrName[this._datatypeIndexValue].NetSuite_DataType = this._dataNSTypeValue;
    arrName[this._datatypeIndexValue].NetSuite_CompareTo = this._defaultNSTypeValue;
    if (this._datatypeIndexValue == (arrName.length - 1))
      arrName.push({ NetSuite: "", NetSuite_DataType: "", NetSuite_CompareTo: "", MarketPlace: "", MarketPlace_DataType: "", MarketPlace_CompareWith: "" });
  }

  onSubmitMRPopupDetails() {
    this.closeMarketPlaceModal.nativeElement.click();
    var arrName = this._datatypeArrname;
    arrName[this._datatypeIndexValue].MarketPlace_DataType = this._dataMATypeValue;
    arrName[this._datatypeIndexValue].MarketPlace_CompareWith = this._dataCPTypeValue;
    arrName[this._datatypeIndexValue].RecordType = this._dataCPRecordTypeValue;
    if (this._datatypeIndexValue == (arrName.length - 1))
      arrName.push({ NetSuite: "", NetSuite_DataType: "", NetSuite_CompareTo: "", MarketPlace: "", MarketPlace_DataType: "", MarketPlace_CompareWith: "", RecordType: "" });
    console.clear();
    console.log(arrName);
  }
  checkHidden(val) {
    if (val != "" && val != null && val != undefined) {
      if (val.toLowerCase().includes('cust'))
        return false;
      else
        return true;
    }
    else
      return true;
  }
  updateShipstationstoremap() {
    this.blockUI.start('Loading...');
    var expresult = this._exportStdFieldMappingResult;
    var Ns_Storedata = '';
    for (var i = 0; i < expresult.length; i++) {
      if (expresult[i].MarketPlace == 'storeId') {
        Ns_Storedata = expresult[i].NetSuite;
      }
    }
    this.blockUI.stop();
    if (Ns_Storedata != '') {

    }
    else {

    }
  }
}