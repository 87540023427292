import { Component, HostListener } from '@angular/core';
import { ConnectorsService } from './servicess/connectors.service'
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ipass';
  constructor(private connectorservice: ConnectorsService, private router: Router) {

  }
  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetTimer() {
    this.connectorservice.notifyUserAction();
  }
}
