import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { Router } from '@angular/router';
import { LogoutComponent } from '../exit/logout/logout.component';
import { AutologoutComponent } from '../exit/autologout/autologout.component';
import { Subject, throwError } from 'rxjs';
import { _MatChipListMixinBase } from '@angular/material';

//const rootURL = "https://localhost:44336/";
//const rootURL = "http://54.164.21.152:8088/";
const rootURL = "https://flexicon.netscoretech.com:446/";
const ScheduleURL = "https://flexicon.netscoretech.com:447/";

@Injectable({
  providedIn: 'root'
})
export class ConnectorsService {
  props = new Subject<any>();
  num = new Subject<any>();
  _NetSuiteItemFieldMapping: any = null;
  _SchedulerFrequencyList: any;
  _SchedulerStartTimeList: any;
  _SchedulerDaysList: any;
  SchedulerArray: any;
  constructor(private http: HttpClient, private route: Router) {
  }
  GetRootURL() {
    return rootURL;
  }
  GetTimeZones() {
    return this.http.get(rootURL + "api/GetTimeZones", {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
  }
  GetAllConnectors(ConnectorType: any) {
    return this.http.get(rootURL + "api/Connectors?ConnectorType=" + ConnectorType, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
  }
  GetSubsidrieslIst() {
    return this.http.get(rootURL + "api/Account/GetSubsidariesList", {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  GetDeveloperIDS() {
    return this.http.get(rootURL + "api/Account/GetDeveloperIDList", {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  GetSchedulerListItems() {
    this._SchedulerFrequencyList = [];
    this._SchedulerStartTimeList = [];
    this._SchedulerDaysList = [];
    this.SchedulerArray = [];
    this._SchedulerFrequencyList = [{ value: "", label: "--Select--" },
    { value: 5, label: "5 Minute's" },
    { value: 10, label: "10 Minute's" },
    { value: 15, label: "15 Minutes" },
    { value: 30, label: "30 Minutes" },
    { value: 60, label: "1 Hour" },
    { value: 120, label: "2 Hour's" },
    { value: 240, label: "4 Hour's" },
    { value: 360, label: "6 Hour's" },
    { value: 480, label: "8 Hour's" },
    { value: 720, label: "12 Hour's" },
    { value: 1440, label: "24 Hour's" },
    { value: 10080, label: "Weekly" },
    { value: 20160, label: "Bi-Weekly" },
    { value: 'Monthly', label: "Monthly" }]
    this._SchedulerStartTimeList.push({ value: "", label: "--Select--" })
    for (var i = 0; i < 24; i++) {
      this._SchedulerStartTimeList.push({ value: i, label: i + ".00" })
    }
    this._SchedulerDaysList = [{ value: 0, label: "Sunday" }, { value: 1, label: "Monday" }, { value: 2, label: "Tuesday" }, { value: 3, label: "Wednesday" }, { value: 4, label: "Thursday" }, { value: 5, label: "Friday" }, { value: 6, label: "Saturday" }];
    this.SchedulerArray.push({ Frequency: this._SchedulerFrequencyList, Time: this._SchedulerStartTimeList, DayList: this._SchedulerDaysList });
    return this.SchedulerArray;
  }
  UpdateAccessAndRefreshTokens() {
    let DataString = 'refresh_token=' + localStorage.getItem('refreshToken') + '&grant_type=refresh_token&client_id=ngAuthApp';
    let headers2 = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded"
    });
    return this.http.post(rootURL + 'token', DataString, { headers: headers2 });
  }

  GetConnectorOverview(id) {
    return this.http.get(rootURL + "/api/GetOverview?integratorId=" + id,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        })
      });
  }
  Getoneyeardata(id) {
    return this.http.get(rootURL + "/api/GetlastoneyearData?integratorId=" + id,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        })
      });
  }

  GetConnectorAnalytics(id) {
    return this.http.get(rootURL + "/api/Order/GetConnectorAnalytics?ConnectorID=" + id,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        })
      });
  }

  Getsevendaysdata(id) {
    return this.http.get(rootURL + "/api/Getsevendaysdata?integratorId=" + id,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        })
      });
  }

  GetConnectorReport(id) {
    return this.http.get(rootURL + "api/Connector/GetOverviewReports?Integratorid=" + id,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        })
      });
  }
  GetOrderAdditionalInfo() {
    return this._NetSuiteItemFieldMapping = this.http.get(rootURL + "/api/Order/GetAdditionaInfo", {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
      })
    })
  }

  GetAcumaticaShippingMethods(id) {
    return this.http.get(rootURL + "api/Account/GetAcumaticaShippingMethods?integratorId=" + id, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
      })
    })
    return this._NetSuiteItemFieldMapping;
  }

  GetAcumaticaPaymentMethods(id) {
    return this.http.get(rootURL + "api/Account/GetAcumaticaPaymentMethods?integratorId=" + id, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
      })
    })
    return this._NetSuiteItemFieldMapping;
  }

  GetOrderFieldMapping() {
    this._NetSuiteItemFieldMapping = this.http.get(rootURL + "api/GetTransactionFields", {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
      })
    })
    return this._NetSuiteItemFieldMapping;
  }
  GetItemFieldMapping(Filter: any, integratorid) {
    var erp = localStorage.getItem('erptype');
    this._NetSuiteItemFieldMapping = this.http.get(rootURL + "api/GetitemFields?Filter=" + Filter + "&integratorID=" + integratorid + "&ErpID=" + erp, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
      })
    })
    return this._NetSuiteItemFieldMapping;
  }
  GetCustomFields(integratorid) {
    return this.http.get(rootURL + "api/Integrator/GetCustomFields?integratorid=" + integratorid, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  GetInventoryAddInfo() {
    return this.http.get(rootURL + "api/Inventory/GetAdditionaInfo?connectorName=" + localStorage.getItem('SelectedconnectorName'), {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  GetProductAdditionlInfo() {
    return this.http.get(rootURL + "api/Product/GetAdditionaInfo?connectorName=" + localStorage.getItem('SelectedconnectorName'), {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
      })
    })
  }
  ListConnector(id: any) {
    return this.http.get(rootURL + "api/Customer/GetIntegratorData?integrationId=" + encodeURIComponent(id),
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        })
      });
  }
  UpdateIOStatusService(id) {
    return this.http.get(rootURL + "api/UpdateIntegratorStatus?integratorID=" + id,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        })
      });
  }
  postCredentails(JsonCredentialsString, AccountName, SubID, id, Tenant, LicenseKey) {
    var BodyData = { IntegratorID: id, CredentialAuthJson: JSON.stringify(JsonCredentialsString), AccountName: AccountName, Subsidary: SubID, Tenant: Tenant, LicenseKey: LicenseKey }
    return this.http.post(rootURL + "api/Integrator/UpdateCredentials", JSON.stringify(BodyData),
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        }),
      });
  }
  UpdateFlowStatusByConnector(FlowUpdateVariable: any, id) {
    var IntegratorID = id;
    return this.http.get(rootURL + "api/Integrator/UpdateFlowStatus?integratorId=" + IntegratorID + "&flowName=" + FlowUpdateVariable,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        })
      });
  }
  Srv_PostImportFieldMapping(FieldMappingJson: any, FieldColumn: any, id) {
    var BodyData = { recordId: id, columnName: FieldColumn, columnValue: JSON.stringify(FieldMappingJson) };
    return this.http.post(rootURL + "api/Integrator/UpdateFieldMapping", JSON.stringify(BodyData),
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        }),
      });
  }
  UpdatefieldbyMap(FieldMappingJson: any, FieldColumn: any, id) {
    var BodyData = { recordId: id, columnName: FieldColumn, columnValue: FieldMappingJson };
    return this.http.post(rootURL + "api/Integrator/UpdateFieldMapping", BodyData,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        }),
      });
  }

  UpdateCustomFields(IntegratorID: any, CustomFields: any) {
    var BodyData = { integratorid: parseInt(IntegratorID), CustomFields: CustomFields };
    return this.http.post(rootURL + "api/Integrator/UpdateCustomFields", JSON.stringify(BodyData),
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        }),
      });
  }

  Srv_UpdateOrderNumber(OrderNumber: any, id) {
    var BodyData = { recordId: id, OrderNumber: OrderNumber };
    return this.http.post(rootURL + "api/Integrator/UpdateOrderNumber", JSON.stringify(BodyData),
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        }),
      });
  }
  TestMarketPlaceConnection(JSonCredentialsData: any, id) {
    var BodyData = { integratorID: id, credentials: JSON.stringify(JSonCredentialsData) };
    return this.http.post(rootURL + "api/MarketPlace/TestConnection", JSON.stringify(BodyData),
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        }),
      });
  }

  TestNetSuiteConnection(RestletURL, AccountID, ConsumerKey, ConsumerSecret, TokenID, TokenSecret) {
    var BodyData = { RestletURL: RestletURL, AccountID: AccountID, ConsumerKey: ConsumerKey, ConsumerSecret: ConsumerSecret, TokenID: TokenID, TokenSecret: TokenSecret };
    return this.http.post(rootURL + "api/Product/DRNetSuiteConnection", JSON.stringify(BodyData),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
      });
  }

  SaveDRNetSuiteCredentials(RestletURL, AccountID, ConsumerKey, ConsumerSecret, TokenID, TokenSecret, LicenseKey) {
    var BodyData = { RestletURL: RestletURL, AccountID: AccountID, ConsumerKey: ConsumerKey, ConsumerSecret: ConsumerSecret, TokenID: TokenID, TokenSecret: TokenSecret, LicenseKey: LicenseKey };
    return this.http.post(rootURL + "api/Integrator/SaveNetSuiteConnection", JSON.stringify(BodyData),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
      });
  }

  GetActiveDRNetSuiteConnections() {
    return this.http.get(rootURL + "api/Integrator/GetNetSuiteConnection", {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
  }

  GetShipmentsAdditionalInfo() {
    return this.http.get(rootURL + "/api/Shipment/GetAdditionaInfo?connectorName=" + localStorage.getItem('SelectedconnectorName'), {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  automaticlogout() {
    localStorage.removeItem('accessToken');
    this.route.navigate(['/home']);
  }

  _userActionOccured: Subject<void> = new Subject();
  get userActionOccured(): Observable<void> {
    return this._userActionOccured.asObservable()
  };

  notifyUserAction() {
    this._userActionOccured.next();
  }

  headers = new HttpHeaders({
    "Content-Type": "application/json"
  });
  options = { headers: this.headers };
  PostNetSuiteDetails(JsonBodyData: any) {
    return this.http.post(rootURL + "api/Customer/NsCredentialUpdate", JsonBodyData, this.options)
  }
  GetNsDetails() {
    return this.http.get(rootURL + "api/Customer", {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  CheckUserToken(Token: any) {
    return this.http.get(rootURL + "api/Customer/CheckUserToken?Token=" + Token, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
  }

  VerifyUserToken(Token: any) {
    return this.http.get(rootURL + "api/Customer/VerifyUser?Token=" + Token, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
  }

  UpdateNsDetails(JsonBodyData: any) {
    return this.http.post(rootURL + "api/Customer/NsCredentialUpdate", JSON.stringify(JsonBodyData),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
      });
  }
  SkipNsDetails(JsonBodyData: any) {
    return this.http.post(rootURL + "api/Customer/SkipNSCredentials", JSON.stringify(JsonBodyData),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
      });
  }
  UpdateNsDetailsManually(JsonBodyData: any) {
    return this.http.post(rootURL + "api/Customer/NsCredentials", JSON.stringify(JsonBodyData),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
      });
  }
  UpdateACDetailsManually(JsonBodyData: any) {
    return this.http.post(rootURL + "api/Customer/AC_Credentials", JSON.stringify(JsonBodyData),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
      });
  }


  GetSupportTickets() {
    return this.http.get(rootURL + "api/Customer/SupportTickets", {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  _AdminGetSupportTickets() {
    return this.http.get(rootURL + "api/Admin/GetSupportTickets", {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  PostSupportTicket(JsonBodyData: any) {
    return this.http.post(rootURL + "api/Customer/SupportTickets", JSON.stringify(JsonBodyData),
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        }),
      });
  }
  update_AccessToken() {
    let data1: any;
    let RefreshToken = localStorage.getItem('refreshToken');
    data1 = 'refresh_token=' + RefreshToken + '&grant_type=refresh_token&client_id=ngAuthApp';
    let headers2 = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded"
    });
    return this.http.post(rootURL + '/token', data1, { headers: headers2 })
  }

  PostAddUser(JsonBodyData: any) {
    return this.http.post(rootURL + "api/SubUser", JSON.stringify(JsonBodyData),
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        }),
      });
  }
  GetSubUsers() {
    return this.http.get(rootURL + "api/SubUser", {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  DeleteSubUsers(loginId) {
    return this.http.delete(rootURL + "api/SubUser?loginId=" + loginId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }

  CheckSubUserToken(Token) {
    Token = encodeURIComponent(Token);
    return this.http.get(rootURL + "api/SubUser/CheckSubUserToken?Token=" + Token, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
  }
  UpdatePassword(JsonBodyData: any) {
    return this.http.post(rootURL + "api/SubUser/UpdatePassword", JSON.stringify(JsonBodyData),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
      });
  }
  CheckResetPasswordToken(Token) {
    Token = encodeURIComponent(Token);
    return this.http.get(rootURL + "api/User/CheckUserToken?Token=" + Token, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
  }
  GetAuditLog(id) {
    return this.http.get(rootURL + "api/GetAuditLog?integratorId=" + id, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  GetAuditLogByDates(FromDate, ToDate, id) {
    return this.http.get(rootURL + "api/GetAuditLogRecords?integratorId=" + id + "&Fromdate=" + FromDate + "&Todate=" + ToDate, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  GetErrorRecords(FromDate, ToDate, id) {
    return this.http.get(rootURL + "api/GetErrorRecords?integratorId=" + id + "&Fromdate=" + FromDate + "&Todate=" + ToDate, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  GetErrorLog(id) {
    return this.http.get(rootURL + "api/GetErrorLog?integratorId=" + id, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  RunIntegrator(ApiMethodName, id) {
    return this.http.get(rootURL + "api/RunIntegrator?integratorId=" + id + "&APIMethodName=" + ApiMethodName, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  RunSingleItem(Itemtype, integratorid, value) {
    var singleApi = ScheduleURL + "/api/Shopify/" + Itemtype + "?integratorId=" + integratorid + "&SingleItemID=" + value;
    var BodyData = { API: singleApi };
    return this.http.post(rootURL + "api/RunSingleProduct", JSON.stringify(BodyData),
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        }),
      });
  }
  GetCustomersList() {
    return this.http.get(rootURL + "api/CustomersList", {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  GetConnectorDetails(ConnectorID) {
    return this.http.get(rootURL + "api/Connector/ConnectorDetails?ConnectorID=" + ConnectorID, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  PostRaiseQuote(JsonBodyData: any) {
    return this.http.post(rootURL + "api/Connector/RaiseQuote", JSON.stringify(JsonBodyData),
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        }),
      });
  }
  GetConnectorTypes() {
    return this.http.get(rootURL + "api/ConnectorTypes", {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
      })
    })
  }
  PostConnector(JsonBodyData: any) {
    return this.http.post(rootURL + "api/Admin/PostConnector", JSON.stringify(JsonBodyData),
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        }),
      });
  }
  GetConnectorsList() {
    return this.http.get(rootURL + "api/Admin/GetConnectors", {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
      })
    })
  }
  GetConnectorByID(connectorID) {
    return this.http.get(rootURL + "api/Admin/GetConnectorByID?connectorid=" + connectorID, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
      })
    })
  }

  GetConnectorNamesList(ERP) {
    return this.http.get(rootURL + "api/Admin/GetConnectorNames?ERP=" + ERP, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
  }
  GetFilesByConnector(connectorID) {
    return this.http.get(rootURL + "api/Admin/GetFilesByConnector?ConnectorID=" + connectorID, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  DeleteFileById(FileId) {
    return this.http.get(rootURL + "api/Admin/DeleteFile?FileID=" + FileId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  GetConnectorDefaultMappings(connectorID) {
    return this.http.get(rootURL + "api/Admin/GetConnectorDefaultMapping?connectorid=" + connectorID, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  PostDefaultFieldMapping(JsonBodyData: any) {
    return this.http.post(rootURL + "api/Admin/PostDefaultMapping", JSON.stringify(JsonBodyData),
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        }),
      });
  }
  PostSubscription(JsonBodyData: any) {
    return this.http.post(rootURL + "api/Admin/CreateSubscription", JSON.stringify(JsonBodyData),
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        }),
      });
  }
  GetSubscriptionPlan(connectorid, subscriptionType, erpType) {
    return this.http.get(rootURL + "api/Admin/GetSubscriptionPlan?connectorid=" + connectorid + "&subscriptionType=" + subscriptionType + "&erpType=" + erpType, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  GetCustomerConnectors(connectorid) {
    return this.http.get(rootURL + "api/Admin/GetCustomerConnectors?CustomerID=" + connectorid, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }

  ActiveIntegrator(JsonBodyData: any) {
    return this.http.post(rootURL + "api/Admin/ActiveIntegrator", JSON.stringify(JsonBodyData),
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        }),
      });
  }
  GetDashboardElements() {
    return this.http.get(rootURL + "api/Admin/GetDashboardValues", {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  GetDashboardTables() {
    return this.http.get(rootURL + "api/Admin/GetDashboardTables", {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }
  GetUserDetails() {
    return this.http.get(rootURL + "api/GetUserDetails?loggedid=" + localStorage.getItem('loggedId'), {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }

  UpdateSupportTicket(JsonBodyData: any) {
    return this.http.post(rootURL + "api/Admin/SupportTicket", JSON.stringify(JsonBodyData),
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        }),
      });
  }
  GetSupportComments(ticketid: any) {
    return this.http.get(rootURL + "api/Admin/GetSupportComments?TicketId=" + ticketid,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        }),
      });
  }

  validateLicense(customerid, appcode: any, licensekey: any) {
    return this.http.get(rootURL + "api/Admin/ValidateLicense?appcode=" + appcode + "&licensecode=" + licensekey + "&Customerid=" + customerid, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      })
    })
  }

  UpdateUserDetails(JsonBodyData: any) {
    return this.http.post(rootURL + "api/Account/UpdateCustomerDetails", JSON.stringify(JsonBodyData),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
      });
  }

  GetERPRecords() {
    return this.http.get(rootURL + "/api/Account/GetERPTypes",
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        })
      });
  }

  GetNetScoreProductRecords() {
    return this.http.get(rootURL + "/api/NetScore/ProductsList",
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        })
      });
  }


  Getlocationsbysubsidary(id) {
    return this.http.get(rootURL + "/api/Account/GetLocationsBySubsidary?integratorId=" + id,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        })
      });
  }
  GetAcumaticalocations(id) {
    return this.http.get(rootURL + "/api/Account/GetAcumaticalocations?integratorId=" + id,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        })
      });
  }

  GetAcumaticaTaxes(id) {
    return this.http.get(rootURL + "/api/Account/GetAcumaticaTaxMethods?integratorId=" + id,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        })
      });
  }

  OrderReprocess(errorid: any, id) {
    return this.http.get(rootURL + "/api/OrderReprocess?integratorId=" + id + "&errorid=" + errorid,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        })
      });
  }
  viewIntegrator(integratorid: any) {
    return this.http.get(rootURL + "api/Admin/ViewIntegrator?IntegratorId=" + encodeURIComponent(integratorid),
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        })
      });
  }
  GetHelpTextData() {
    return this.http.get(rootURL + "api/Customer/GetHelpTextData",
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        })
      });
  }
  LoginError(email: any) {
    return this.http.get(rootURL + "api/User/LoginError?Email=" + email,
      {
        headers: new HttpHeaders({

        })
      });
  }
  PostHelpTextData(JsonBodyData: any) {
    return this.http.post(rootURL + "api/Admin/CreateHelpText", JSON.stringify(JsonBodyData),
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        }),
      });
  }
}
